import { atom, selectorFamily } from "recoil"
import { User } from "./user_group_atom"
import { shoppingListActions } from "../actions/shopping_list_actions"

export type ShoppingList = {
  id: string
  owner: User
  shopping_list_items: ShoppingListItem[]
}

export enum ShoppingListItemVisibility {
  Bought = "bought",
  NotBought = "not_bought"
}

export type ShoppingListItem = {
  id: string
  title: string
  state: ShoppingListItemVisibility
}

export const shoppingListsState = atom<ShoppingList[]>({
  key: "shoppingListsState",
  default: []
})

export const shoppingListsAtom = selectorFamily<ShoppingList[], string>({
  key: 'shoppingListsAtomKey',
  get: (groupId: string) => async ({ get }) => {
    const shoppingLists = get(shoppingListsState); // You can decide how to use this
    const fetchedLists = await shoppingListActions().index(groupId);
    return fetchedLists; // You can integrate fetchedLists with shoppingLists based on your logic
  },
  // Optionally, you can also implement a set for directly updating your atom from the selector
  set: (groupId: string) => ({ set }, newValue) => {
    set(shoppingListsState, newValue as ShoppingList[]);
  },
});
import { useState } from "react";
import { Button, Card, Row, Col, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import InviteMembers from '../../components/group/invite_form';

export default function InviteOtherView() {

  const INITIAL_STATE = 0
  const INVITING_MEMBERS = 1

  const navigate = useNavigate()
  const [viewState, setViewState] = useState(INITIAL_STATE)

  return (
    <Card className='view-container'>
      {viewState === INITIAL_STATE && (
        <Row gutter={[8, 24]} className="text-align-center" >
          <Col span={24}>
            <Typography.Title level={2} className="view-title">
              Do You Want to Invite Other People to Your Kitchen?
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Space>
              <Button type="primary" size="large" shape="round" htmlType="submit" onClick={() => navigate('/')}>
                No
              </Button>
              <Button type="primary" size="large" shape="round" htmlType="submit" onClick={() => setViewState(INVITING_MEMBERS)}>
                Yes
              </Button>
            </Space>
          </Col>
        </Row>
      )}
      {viewState === INVITING_MEMBERS && (
        <Row gutter={[8, 24]} align="middle">
          <Col span={24}>

            <Typography.Title level={2} className="view-title">
              Invite Cooks to Your Kitchen
            </Typography.Title>

            <Typography.Paragraph className="text-align-center">
              You can invite up to 11 people.
            </Typography.Paragraph>

            <Typography.Title level={4}>
              Send Invitation Email
            </Typography.Title>
            <InviteMembers />

            <Row gutter={[8, 8]} justify="center" align="middle">
              <Col span={8}>
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  onClick={() => navigate('/')}>
                  Done
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  );
}
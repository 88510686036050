import {Button, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilValueLoadable} from "recoil";

import {useEaterRecipeReviewActions, EaterRecipeReviewParameters} from "../../actions/eater_recipes_review_actions";

import {userGroupRecipeQuery} from "../../atoms/recipes_atom";

import type {Option, Options, Choice, Choices} from '../../components/choiceui/types.js'
import {RecipeThumb} from "../../components/recipe/RecipeThumb"
import {YesNo} from "../../components/choiceui/YesNo"
import Navbar from "../../components/Navbar"

export default function RecipeReviewEatingView() {
  const navigate = useNavigate()
  const params = useParams()
  const recipeId = String(params.id!)

  const eaterRecipeReviewActions = useEaterRecipeReviewActions()
  const recipeQuery = useRecoilValueLoadable(userGroupRecipeQuery(recipeId))

  const goToMenu = () => {
    navigate('/')
  }

  function handleClick (choice: Choice) {
    const params: EaterRecipeReviewParameters = {
      recipe_id: recipeId,
      would_eat_again: choice
    }
    eaterRecipeReviewActions.updateReview(params)
    .then(() => eaterRecipeReviewActions.publishReview(recipeId))
    if (choice === false) {
      navigate(`/recipe/${recipeId}/review/eating_feedback`)
    } else {
      navigate(`/recipe/review/thanks`)
    }
  }

  return (
    <div className='view-container'>
      <Navbar>
        <Button type="link" shape="round" size="small" onClick={goToMenu}>
        ← Back to Recipes
        </Button>
      </Navbar>
      <YesNo
        legend={
          <Typography.Title level={2} className="view-title">
            Would You Eat This Dish Again?
          </Typography.Title>
        }
        onClick={handleClick}
        no={{
          label: <Button type="primary" size="large" shape="round">👎 No</Button>,
          value: false
        }}
        yes={{
          label: <Button type="primary" size="large" shape="round">👍 Yes</Button>,
          value: true,
        }}
      >
        {recipeQuery.state === 'hasValue' && (
          <RecipeThumb
            emoji={recipeQuery.contents.data.emoji}
            title={recipeQuery.contents.data.recipe}
          />
        )}
      </YesNo>
    </div>
  )
}
import { useState } from "react";
import { Form, Button, Divider, Input, Typography, message } from "antd";
import { useRecoilState } from "recoil";

import { useSessionActions } from '../../actions/session_actions';
import { userProfileAtom } from "../../atoms/user_profile_atom";


export default function CreateAccountView() {

  const INITIAL_STEP = 0
  const MAGIC_LINK_SENT = 1

  const [viewState, setViewState] = useState(INITIAL_STEP)
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom)
  const sessionActions = useSessionActions();

  const getLink = (values: any) => {
    if (!values.email || !userProfile) { return }
    sessionActions.getMagicLink(values.email, userProfile)
      .then((_response) => { })
      .catch((err) => {
        message.warning("Can't get magic link...")
      })
      .finally(() => setViewState(MAGIC_LINK_SENT))
  }

  return (
    <>
      {viewState === INITIAL_STEP && (
        <>
          <div className="text-align-center">
            <Typography.Title level={1}>
              Confirm Account
            </Typography.Title>
            <Typography.Paragraph className="ant-typography-lg">
              Write your email, and we will send you a magic link that you can use to confirm your account and log in.
            </Typography.Paragraph>
            <Form
              name="createAccountWithEmail"
              onFinish={getLink}
              layout="vertical"
              requiredMark={false}
            >
              <Form.Item
                name="email"
                label={"Email"}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email address.'
                  },
                  {
                    required: true,
                    message: 'Please enter a valid email address.',
                  },
                ]}
              >
                <Input
                  // value={userProfile?.email}
                  style={{ maxWidth: '400px' }}
                  className="width-full"
                />
              </Form.Item>
              <Form.Item className="text-align-center">
                <Button type="primary" shape="round" size="large" htmlType="submit">
                  Send Link
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
      {viewState === MAGIC_LINK_SENT && (
        <div className="text-align-center">
          {/* TODO: this part should maybe be the same component as the one in landing_view.tsx. Something like configm_login.tsx */}
          <Typography.Title level={1}>
            Check Your Email
          </Typography.Title>
          <Typography.Paragraph className="ant-typography-lg">
            We sent you a magic link. Click the link to confirm your email and log in.
          </Typography.Paragraph>
          <Divider />
          <Typography.Paragraph>
            Didn't receive the link? First, check your spam folder, then try again.
          </Typography.Paragraph>
          <Typography.Paragraph>
            {/* TODO: this button should take the user back to that previous step in this view, where we ask the email, not to the initial step. */}
            <Button size="small" htmlType="submit" onClick={() => setViewState(INITIAL_STEP)}>
              Try Again
            </Button>
          </Typography.Paragraph>
        </div>
      )}
    </>
  );
}
import { LifeLongClient } from "../axios"

export const invitationsActions = () => {
  const client = LifeLongClient()

  async function index(user_group_id: number) {
    const { data } = await client.client().get(`/user_groups/${user_group_id}/invitations`)
    return data
  }

  async function create(user_group_id: number, email: string) {
    const { data } = await client.client().post(`/user_groups/${user_group_id}/invitations`, { email: email })
    return data
  }

  async function destroy(user_group_id: number, id: number) {
    const { data } = await client.client().delete(`/user_groups/${user_group_id}/invitations/${id}`)
    return data
  }

  async function accept(user_group_id: number, id: number) {
    const { data } = await client.client().patch(`/user_groups/${user_group_id}/invitations/${id}/accept`)
    return data
  }

  async function decline(user_group_id: number, id: number) {
    const { data } = await client.client().patch(`/user_groups/${user_group_id}/invitations/${id}/decline`)
    return data
  }

  async function received_invitations() {
    const { data } = await client.client().get(`/received_invitations`)
    return data
  }

  return {
    accept: accept,
    decline: decline,
    index: index,
    create: create,
    destroy: destroy,
    received_invitations: received_invitations
  }
}
import React, { FC, createContext, useEffect, useState, ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { createCable } from "@anycable/web"
import { Cable } from "@anycable/core"
import { sessionState } from '../atoms/session';

interface VueCableProviderProps {
  children?: ReactNode
}

export type VueCableProviderType = {
  cable?: Cable;
};

interface VueCableContextType {
  cable: Cable | undefined;
}

export const VueCableContext = createContext<VueCableContextType | null>(null);

export const VueCableProvider: FC<VueCableProviderProps> = ({ children }) => {
  const [cable, setCable] = useState<Cable | undefined>(undefined);  
  const session = useRecoilValue(sessionState)

  useEffect(() => {
    if (!session) { return }
    const url = `${baseUrl()}/cable?auth_token=${session.access_token}`
    const newCable = createCable(url)
    newCable.on('connect', event => {
      console.log('cable connected', event)
    })
    newCable.on('disconnect', ev => {
      console.log('cable disconnect', ev.reason)
    })
    setCable(newCable)
  }, [session]);

  function baseUrl() {
    if (!process.env.REACT_APP_VUO_SOCKET_URL) {
      return `ws://localhost:80/api`
    } else {
      return `${process.env.REACT_APP_VUO_SOCKET_URL}`
    }
  }

  return (
    <VueCableContext.Provider value={{ cable }}>  {/* Provide the cable object via context */}
      {children}
    </VueCableContext.Provider>
  );
};

export default VueCableProvider;
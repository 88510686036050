import { useState, ReactNode } from "react"

export interface NavbarProps {
  children?: ReactNode
}

export default function Navbar (props: NavbarProps) {
  return (
    <header className="navbar">
      {props.children}
    </header>
  )
};
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  shouldReloadRepicesAtom,
  recipesAtom,
  teaserRecipeAtoms,
  RecipeStatus
} from "../atoms/recipes_atom";
import { LifeLongClient } from '../axios'
import { userProfileAtom } from '../atoms/user_profile_atom';

function useRecipesActions() {

  const client = LifeLongClient()
  const userProfile = useRecoilValue(userProfileAtom)
  const [teaserRecipes, setTeaserRecipes] = useRecoilState(teaserRecipeAtoms)
  const [shouldReload, setShouldReload] = useRecoilState(shouldReloadRepicesAtom)
  const [recipes, setRecipes] = useRecoilState(recipesAtom)

  async function fetchTeaserRecipes() {
    let allergies: string[] = []
    let dietaryRestrictions: string[] = []

    if (userProfile?.allergies) { allergies = allergies.concat(userProfile.allergies.map(a => a.name)) }
    if (userProfile?.otherAllergy) { allergies = allergies.concat(userProfile.otherAllergy) }
    if (userProfile?.dietary_restrictions) { dietaryRestrictions = dietaryRestrictions.concat(userProfile.dietary_restrictions.map(dr => dr.name)) }
    if (userProfile?.otherDietaryRestriction) { dietaryRestrictions = dietaryRestrictions.concat(userProfile.otherDietaryRestriction) }

    const params =
      `allergies: ${allergies.join(', ')}; ` +
      `dietary restrictions: ${dietaryRestrictions.join(', ')};`

    if (teaserRecipes.length === 0 || shouldReload) {
      const { data } = await client.client().post('/teaser_recipes', { info: params })
      if (data) {
        setTeaserRecipes(data)
        setShouldReload(false)
      }
    }
  }

  async function fetchGroupRecipes() {
    const { data } = await client.client().get('/recipes')
    if (data) {
      setRecipes(data)
    }
  }

  async function updateRecipeStatus(recipeId: string, status: RecipeStatus) {
    const { data } = await client.client().patch(`/recipes/${recipeId}`, { status: status })
    if (data) {
      const newRecipes = recipes.map(recipe =>
        recipe.id === data.id ? data : recipe)
      setRecipes(newRecipes)
    }
  }

  async function regenerateRecipes() {
    const { data } = await client.client().post(`/recipes/regenerate`)
    return data
  }

  return {
    fetchGroupRecipes,
    fetchTeaserRecipes,
    regenerateRecipes,
    updateRecipeStatus
  }
}

export { useRecipesActions }
import React from "react";
import {
  Navigate,
  useLocation
} from 'react-router-dom';
import { useRecoilValue } from 'recoil'

import { sessionState } from '../../atoms/session'

type Props = {
  children: JSX.Element
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const session = useRecoilValue(sessionState);
  const location = useLocation();

  if (!session) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
import {useState} from "react";
import {Form, Input, Typography, Affix, Button} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import {ReasonNotToEat, useEaterRecipeReviewActions} from "../../actions/eater_recipes_review_actions";

import {EaterRecipeReviewParameters} from "../../actions/eater_recipes_review_actions";
import type {Option, Options, Choice, Choices} from '../../components/choiceui/types.js'
import {MultipleChoice} from "../../components/choiceui/MultipleChoice";
import Navbar from "../../components/Navbar"

export default function RecipeReviewEatingFeedbackView() {
  const navigate = useNavigate()
  const params = useParams()
  const recipeId = String(params.id!)

  const eaterRecipeReviewActions = useEaterRecipeReviewActions()

  const [selectedReasons, setSelectedReasons] = useState<ReasonNotToEat[]>([])
  //TODO: should maybe be customReasons, so multiple reasons. Just to be consistent with the use of add more component. The add more field could of course be just a text input.
  const [customReason, setCustomReason] = useState<string>("")

  const options = [
    { label: "I didn't like the taste", value: ReasonNotToEat.DidntLikeTaste },
    { label: "I didn't like the presentation", value: ReasonNotToEat.DidntLikePresentation },
    { label: "I didn't like the ingredients", value: ReasonNotToEat.DidntLikeIngredients },
  ]

  const goToMenu = () => {
    navigate('/')
  }

  const prevClicked = () => {
    // TODO
  }
  const nextClicked = () => {
    const params: EaterRecipeReviewParameters = {
      recipe_id: recipeId,
      reason_not_to_eat_again: selectedReasons[0],
      open_text_reason: customReason
    }
    eaterRecipeReviewActions.updateReview(params)
      .then(() => eaterRecipeReviewActions.publishReview(recipeId))
    navigate(`/recipe/review/thanks`)
  }

  return (
    <div className='view-container'>
      <Navbar>
        <Button type="link" shape="round" size="small" onClick={goToMenu}>
        ← Back to Recipes
        </Button>
      </Navbar>
      <MultipleChoice
        legend={
          <Typography.Title level={2} className="view-title">
            Why Not?
          </Typography.Title>
        }
        onChange={(choices: Choices) => setSelectedReasons([...choices])}
        options={options}
        renderOptionLabel={(label: any, checked) => {
          return (
            <>{label}</>
          )
        }}
      />
      <Form layout="vertical">
        <Form.Item
          label={
            <Typography.Title level={4}>
              Something else?
            </Typography.Title>
          }
        >
          <Input onChange={(e) => setCustomReason(e.target.value)} />
        </Form.Item>
      </Form>
      <Affix offsetBottom={0}>
        <div className="actionbar">
          <Button onClick={prevClicked} type="text" size="large" shape="round" htmlType="submit">
            ← Back
          </Button>
          <Button onClick={nextClicked} type="primary" size="large" shape="round" htmlType="submit">
            Next →
          </Button>
        </div>
      </Affix>
    </div>
  )
}
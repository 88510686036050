import { useEffect, useRef } from 'react';
import { Typography, Col, Row, Spin } from 'antd';
import { useNavigate, useParams } from "react-router-dom";

import { useSetRecoilState } from 'recoil'
import { sessionState } from '../../atoms/session'
import { useSessionActions } from '../../actions/session_actions';

const VerifyTokenView = () => {

  const navigate = useNavigate()
  const { token } = useParams()
  const sessionActions = useSessionActions()
  const setSessionAtom = useSetRecoilState(sessionState)

  const tokenVerifying = useRef(false)

  useEffect(() => {
    if (!token || tokenVerifying.current) { return }
    tokenVerifying.current = true

    sessionActions.verifyToken(token).then(({data}) => {
      localStorage.setItem("sessionStorageKey", JSON.stringify(data));
      setSessionAtom(data)
      navigate('/')
    })
  }, [token])

  return (
    <div className="view-container">
      <Row className="text-align-center" gutter={[8, 8]}>
        <Col span={24}>
          <Typography.Title>&nbsp;</Typography.Title>
        </Col>
        <Col span={24}>
          <Spin />
        </Col>
        <Col span={24}>
          Just a second, logging you in.
        </Col>
      </Row>
    </div>
  )
}

export default VerifyTokenView;
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Affix, Button, Typography, Card} from "antd";
import {useSetRecoilState} from 'recoil';
import {UserGroupRecipesQueryID} from '../../atoms/recipes_atom';

export default function RecipeReviewSkillView() {

  const navigate = useNavigate();
  const setUserGroupRecipesQueryID = useSetRecoilState(UserGroupRecipesQueryID)

  useEffect(() => {
    setUserGroupRecipesQueryID(id => id + 1)
  }, [])

  return (
    <Card className='view-container text-align-center'>
      <Typography.Title level={1}>
        Thank You!
      </Typography.Title>
      <Typography.Paragraph className='ant-typography-lg'>
        Your reviews make recipes and chefs in your kitchen better.
        <br />
        Keep ‘em coming!
      </Typography.Paragraph>
      <Affix offsetBottom={0}>
        <div className="actionbar">
          <Button type="primary" shape="round" size="large" onClick={() => navigate("/")}>
            Back to Recipes
          </Button>
        </div>
      </Affix>
    </Card>
  )
}
import { useState, ReactNode } from "react"

import { Button, Col, Row } from "antd"

export interface StepsNavProps {
  back?: ReactNode
  next?: ReactNode
  skip?: ReactNode
}

// TODO: This component should export button press event on yes and no buttons

// Very similar to multiple choice in functionality, but from a visual stand point choose one should have less items than multiple choice and thus each choice could be visually more prominent or have more supporting information per choice.
export function StepsNav (props: StepsNavProps) {

  function goBack () {}
  function goNext () {}
  function goSkip () {}

  return (
    <div className="buttons">
      {props.back && (
        <Button
          type="text"
          shape="round"
          onClick={goBack}
        >
          {props.back}
        </Button>
      )}
      {props.next && (
        <Button
          type="primary"
          size="large"
          shape="round"
          onClick={goNext}
        >
          {props.next}
        </Button>
      )}
      {props.skip && (
        <Button
          type="text"
          shape="round"
          onClick={goSkip}
        >
          {props.skip}
        </Button>
      )}
    </div>
  )
}
import { Typography } from "antd"

import DietaryRestrictionForm from "../../components/profile/dietary_restrictions_form";

export default function DietaryRestrictionsView() {
  return (
    <>
      <Typography.Title level={1}>
        Dietary Restrictions
      </Typography.Title>
      <Typography.Paragraph>
        Choose any dietary restrictions you follow. We will tailor recipes according to all dietart restrictions in your group.
      </Typography.Paragraph>
      <Typography.Paragraph>
        You can change these later any time on your profile page.
      </Typography.Paragraph>
      <DietaryRestrictionForm />
    </>
  )
}

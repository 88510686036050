import { atom } from "recoil"
import { localStorageEffect } from "./local_storage_effect"

export type Session = {
  access_token: string
}

export const sessionState = atom<Session | null>({
  key: "sessionState",
  default: null,
  effects: [localStorageEffect("sessionStorageKey")],
})

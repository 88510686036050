import { useState } from "react";
import { Space, Button, Tag, Input, Form, Row, Col, Typography, message } from "antd";
import { useUserProfileActions } from "../../actions/user_profile_actions";
import { useRecoilValue } from "recoil";
import { userProfileAtom } from "../../atoms/user_profile_atom";
import { AddMore } from "../choiceui/AddMore.Ant"

export default function InviteView() {

  const [form] = Form.useForm();
  const [messageApi, messageContextHolder] = message.useMessage();
  const userProfileActions = useUserProfileActions()
  const userProfile = useRecoilValue(userProfileAtom)

  const [email, setEmail] = useState("")
  const [invites, setInvites] = useState([] as string[])

  const addToInvites = () => {

  }
  const onChangeEmail = (value: string) => {
    setEmail(value)
  }

  return (
    <>
      {messageContextHolder}
      <AddMore
        label="Add more people to group"
        button="Send invitation"
        onAdd={(value) => {
          console.log("onAdd", value)
        }}
        onRemove={(value) => {
          console.log("onRemove", value)
        }}
      />
      {/* <Form
        form={form}
        name="emailInvitation"
        onFinish={addToInvites}
        layout="vertical"
        requiredMark={false}
      >
        <Row gutter={[8, 0]} align="bottom">
          <Col flex="1 0 auto">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid email address.'
                },
                {
                  required: true,
                  message: 'Please enter a valid email address.',
                },
              ]}
            >
              <Input
                onChange={(e) => { onChangeEmail(e.target.value) }}
                value={email}
                placeholder='Type email here'
              />
            </Form.Item>
          </Col>
          <Col flex="0 0 min-content">
            <Form.Item>
              <Button onClick={addToInvites} htmlType="submit">
                Send Invitation
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Space size={[8, 8]} wrap>
        {invites.length > 0 && (
          <>
            {invites.map((email, id) => (
              <>
                <Tag key={id}>
                  {email}
                </Tag>
              </>
            ))}
          </>
        )}
      </Space> */}
    </>
  );
}
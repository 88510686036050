import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Affix, Button, Typography } from "antd";

import { RecipeStatus, userGroupRecipeQuery } from '../../atoms/recipes_atom';
import { useRecoilValueLoadable } from 'recoil';
import { useRecipesActions } from '../../actions/recipes_actions';

import Navbar from "../../components/Navbar"
import NutritionLabelCard from "../../components/recipe/nutrition_label_card";
import RecipeRequirementsCard from "../../components/recipe/recipe_requirements_card";
import RecipeStepsCard from "../../components/recipe/recipe_steps_card";
import { RecipeThumb } from "../../components/recipe/RecipeThumb"
import AvatarRound from "../../components/group/avatar_round"

export default function RecipeView() {

  const navigate = useNavigate();
  const params = useParams();
  const recipesActions = useRecipesActions()
  const recipe = useRecoilValueLoadable(userGroupRecipeQuery(String(params.id!)))

  function goToMenu() {
    navigate("/")
  }

  function goToFirstStep(recipeId: string) {
    recipesActions.updateRecipeStatus(recipeId, RecipeStatus.cooking)
    navigate(`/recipe/${recipeId}/step/1`)
  }

  return (
    <div className="view-container">
      <Navbar>
        <Button type="link" shape="round" size="small" onClick={goToMenu}>
          ← Recipes
        </Button>
      </Navbar>
      {recipe.state === 'hasValue' && (
        <>
          <Typography.Title level={1}>
            {/* TODO: Do we want to show relative days here, like Today and Tomorrow? Or just day names like Monday, Tuesday etc? */}
            Monday Dinner
          </Typography.Title>
          <div className="recipe-layout">
            <RecipeThumb
              emoji={recipe.contents.data.emoji}
              title={recipe.contents.data.recipe}
              avatars={
                <Avatar.Group>
                  <AvatarRound
                    nickname={recipe.contents.main_seat.user_profile.nick_name || ""}
                    initials={recipe.contents.main_seat.user_profile.nick_name?.substring(0, 2) || ""}
                    email={recipe.contents.main_seat.email}
                  />
                  {recipe.contents.fill_in_seat && (
                    <AvatarRound
                      nickname={recipe.contents.fill_in_seat.user_profile.nick_name || ""}
                      initials={recipe.contents.fill_in_seat.user_profile.nick_name?.substring(0, 2) || ""}
                      email={recipe.contents.fill_in_seat.email}
                    />
                  )}
                </Avatar.Group>
              }
              tags={[
                recipe.contents.data.servings + " servings",
                recipe.contents.data.preparation_time
              ]}
            />
            <NutritionLabelCard recipe={recipe.contents} />
            <RecipeRequirementsCard recipe={recipe.contents} />
            <RecipeStepsCard recipe={recipe.contents} />
          </div>
          <Affix offsetBottom={0}>
            <div className="actionbar">
              <Button size="large" shape="round" onClick={() => goToFirstStep(recipe.contents.id)} className='ant-btn-float'>
                Begin Cooking →
              </Button>
            </div>
          </Affix>
        </>
      )}
    </div>
  );
}
import {useState, ReactNode, ReactElement, cloneElement} from "react"
import {Tag, Space, Typography} from "antd"

type Quest = {
  icon: ReactNode,
  text: ReactNode,
  color: string
}
type Quests = Quest[]

export interface QuestListProps {
  quests: Quests
}

export function QuestList (props: QuestListProps) {
  return (
    <div className="view-container">
      <Typography.Title className="text-align-center">
        Quests
      </Typography.Title>
      <Space wrap className="justify-content-center">
        {props.quests.map((quest, i) => {
          return (
            <Tag color={quest.color} className="ant-tag-quest">
              <div>
                {quest.text}
              </div>
              <div className="ant-tag-quest-icon">
                {quest.icon}
              </div>
            </Tag>
          )
        })}
      </Space>
    </div>
  )
}
import { useState } from 'react'
import { Form, Button, Col, Divider, Input, message, Row, Typography, } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useUserProfileActions } from '../../actions/user_profile_actions';
import { useSessionActions } from '../../actions/session_actions';
import { userProfileAtom } from "../../atoms/user_profile_atom";

const LandingView = () => {
  const INITIAL_STEP = 0
  const MAGIC_LINK_SENT = 1

  const sessionActions = useSessionActions()
  const userProfileActions = useUserProfileActions()

  const [viewState, setViewState] = useState(INITIAL_STEP)

  const navigate = useNavigate();

  const getLink = (values: any) => {
    if (!values.email) { return }

    sessionActions.getMagicLinkLogin(values.email).then(() => {
      setViewState(MAGIC_LINK_SENT)
    }).catch((err) => {
      message.warning("Can't get magic link...")
    })
  }

  return (
    <div className="view-container">
      {viewState === INITIAL_STEP && (
        <>
          <Typography.Title level={1} className="view-title">
            <div className="view-subtitle">Welcome to</div>
            FixFood Alpha
          </Typography.Title>
          <Typography.Title level={3}>
            Hey there, Culinary Pioneer! 
          </Typography.Title>
          <Typography.Paragraph className="ant-typography-lg">
            Huge thanks for joining us on this exciting journey. Your insights will help us cook up the perfect app, so don't hold back—let us know what you think!
          </Typography.Paragraph>
          <Typography.Paragraph className="ant-typography-lg">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              onClick={() => {
                userProfileActions.removeUserProfile()
                navigate("/signup")
              }}
            >
              Sign up
            </Button>
          </Typography.Paragraph>

          <Form
            name="signInWithEmail"
            onFinish={getLink}
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={[8, 8]} align="bottom">
              <Col flex="1 0 auto">
                <Form.Item
                  name="email"
                  label={
                    <div className="ant-typography-lg">
                      Have account already?
                    </div>
                  }
                  rules={[
                    {
                      type: 'email',
                      message: 'Please enter a valid email address.'
                    },
                    {
                      required: true,
                      message: 'Please enter a valid email address.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="0 0 min-content">
                <Form.Item>
                  <Button
                    htmlType="submit"
                  >
                    Log In
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          
          <br />
          <br />
          <Typography.Paragraph>
            <b>Heads Up: You're Exploring Our Alpha Version! 🌟</b>
            <br />
            This version has limited features and you might spot a bug or two. 
            <br />
            We'd love to hear your thoughts as we perfect FixFood!
          </Typography.Paragraph>
          <br />
        </>
      )}

      {viewState === MAGIC_LINK_SENT && (
        <div className="text-align-center">
          <Typography.Title level={1}>
            Check Your Email
          </Typography.Title>
          <Typography.Paragraph className="ant-typography-lg">
            We sent you a magic link. Click the link to log in.
          </Typography.Paragraph>
          <Divider />
          <Typography.Paragraph>
            Didn't receive the link? First, check your spam folder, then try again.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Button size="small" htmlType="submit" onClick={() => setViewState(INITIAL_STEP)}>
              Try Again
            </Button>
          </Typography.Paragraph>
        </div>
      )}
    </div>
  )
}

export default LandingView;
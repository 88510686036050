import { atom, selector, selectorFamily } from 'recoil'
import { localStorageEffect } from './local_storage_effect';
import { User, UserGroup } from './user_group_atom';
import { userGroupRecipesActions } from '../actions/user_group_recipes_actions';

export enum RecipeStatus {
  awaiting = "awaiting",
  cooking = "cooking",
  finished = "finished"
}

export type PreparationStep = {
  description: string
  ingredients?: {
    [key: string]: string
  }
  emoji: string
  needed_tools?: string[]
}

export type Recipe = {
  description: string;
  mainIngredient: string;
  title: string;
}

export type RecipeData = {
  emoji: string
  estimated_price: string
  ingredients: {
    [key: string]: string
  }
  needed_tools: string[]
  nutritional_values: {
    [key: string]: string
  }
  preparation_steps: PreparationStep[]
  preparation_time: string
  recipe: string
  servings: number
}

export type ChefRecipeReview = {
  completed: boolean
  id: number
  reviewed_chef?: User
  reviewer: User
  skipped?: boolean
}

export type EaterRecipeReview = {
  id: number
  published_at?: string
  reviewer: User
  skipped?: boolean
}

export type GroupRecipe = {
  // If recipe have CRR for current user
  chef_review?: ChefRecipeReview
  cooking_date: string
  data: RecipeData  
  data_fetch_state: string
  // If recipe have ERR for current user
  eater_review?: EaterRecipeReview
  fill_in_seat?: User
  id: string
  main_seat: User
  status: RecipeStatus
}

export const shouldReloadRepicesAtom = atom<boolean>({ key: 'shouldReloadRepicesAtom', default: false });

export const teaserRecipeAtoms = atom<Recipe[]>({
  key: 'teaserRecipeAtoms',
  default: [],
  effects: [localStorageEffect('OnboardingRecipeKey')]
})

export const recipesAtom = atom<GroupRecipe[]>({
  key: 'recipesAtoms',
  default: []
})

export type UserGroupRecipesQueryParams = {
  groupId: string | null;
}

export const userGroupRecipeQuery = selectorFamily<GroupRecipe, string | null>({
  key: 'userGroupRecipesQuery',
  get: (recipeId) => async () => {
    if (recipeId === null) { return null }
    return await userGroupRecipesActions().show(recipeId)
  }
});

export const userGroupRecipesQuery = selectorFamily<GroupRecipe[], UserGroupRecipesQueryParams>({
  key: 'userGroupRecipesQuery',
  get: ({ groupId }) => async ({ get }) => {
    get(UserGroupRecipesQueryID)
    if (groupId === null) { return [] }
    return await userGroupRecipesActions().index(groupId);
  }
});

export const UserGroupRecipesQueryID = atom({
  key: 'UserGroupRecipesQueryID',
  default: 0
})

// Filtered recipes: If they are not finished or there is review which is not completed
export const filteredRecipesSelector = selectorFamily<GroupRecipe[], UserGroupRecipesQueryParams>({
  key: 'filteredRecipesSelector',
  get: (params) => async ({ get }) => {
    const recipes = get(userGroupRecipesQuery(params))
    return recipes.filter((recipe) =>
      (recipe.chef_review?.id && recipe.chef_review.completed == false) ||
      (recipe.eater_review?.id && recipe.eater_review.published_at === null)
    )
  }
})
import { Typography } from "antd"

import AllergiesForm from "../../components/profile/allergies_form";

export default function AllergiesView() {

  return (
    <>
      <Typography.Title level={1}>
        Allergies
      </Typography.Title>
      <Typography.Paragraph>
        Choose any allergies you have. We will tailor recipes according to all allergies in your group.
      </Typography.Paragraph>
      <Typography.Paragraph>
        You can change these later any time on your profile page.
      </Typography.Paragraph>
      <AllergiesForm />
    </>
  )
}

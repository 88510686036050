import {useEffect} from "react";
import {Button, Typography} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilValue, useRecoilValueLoadable} from "recoil";

import {useChefRecipeReviewActions} from "../../actions/chef_recipes_review_actions";
import {ReviewedChefFeedback} from "../../actions/recipes_review_actions";
import {ChefRecipeReviewParameters} from "../../actions/recipes_review_actions";
import {chefRecipeReviewAtom} from "../../atoms/chef_recipe_review";
import {userGroupRecipeQuery} from "../../atoms/recipes_atom";
import type {Option, Options, Choice, Choices} from '../../components/choiceui/types.js'

import Navbar from "../../components/Navbar"
import {RecipeThumb} from "../../components/recipe/RecipeThumb"
import {YesNo} from "../../components/choiceui/YesNo"

export default function RecipeReviewSkillView() {
  const navigate = useNavigate()
  const params = useParams()

  const chefRecipeReviewActions = useChefRecipeReviewActions()

  const review = useRecoilValue(chefRecipeReviewAtom)

  const recipeId = String(params.id!)
  const recipeQuery = useRecoilValueLoadable(userGroupRecipeQuery(recipeId))


  useEffect(() => {
    if (!recipeId) { return }
    chefRecipeReviewActions.fetchChefRecipeReview(recipeId)
  }, [recipeId])

  const goToMenu = () => {
    navigate('/')
  }

  const handleClick = (choice: Choice) => {
    const params: ChefRecipeReviewParameters = {
      recipe_id: recipeId,
      reviewed_chef_feedback: choice
    }
    chefRecipeReviewActions.updateChefRecipeReview(params)
      .then(() => chefRecipeReviewActions.publishChefRecipeReview(recipeId))
    navigate('/recipe/review/thanks')
  }

  return (
    <div className='view-container'>
      <Navbar>
        <Button type="link" shape="round" size="small" onClick={goToMenu}>
        ← Back to Recipes
        </Button>
      </Navbar>
      {review && review.reviewed_chef && (
        <YesNo
          legend={
            <Typography.Title level={2} className="view-title">
              Did {review.reviewed_chef.user_profile.nick_name} do Well With This Recipe?
            </Typography.Title>
          }
          onClick={handleClick}
          no={{
            label: <Button type="primary" shape="round" size="large" style={{ width: "300px", flex: "0 0 300px" }}>🤏 Needs Practice</Button>,
            value: ReviewedChefFeedback.neutral
          }}
          yes={{
            label: <Button type="primary" shape="round" size="large" style={{ width: "300px", flex: "0 0 300px" }}>👍 Did Well</Button>,
            value: ReviewedChefFeedback.positive
          }}
        >
          {recipeQuery.state === 'hasValue' && (
            <RecipeThumb
              emoji={recipeQuery.contents.data.emoji}
              title={recipeQuery.contents.data.recipe}
            />
          )}
        </YesNo>
      )}
    </div>
  )
}
import { useRef } from "react";
import { useRecoilValue } from "recoil"
import { InputNumber, Form, message } from "antd"

import { userProfileAtom } from "../../atoms/user_profile_atom"
import { useUserProfileActions } from "../../actions/user_profile_actions"

export default function BudgetForm() {

  const [messageApi, messageContextHolder] = message.useMessage();
  const userProfile = useRecoilValue(userProfileAtom)
  const userProfileActions = useUserProfileActions()
  const timeoutHandler = useRef<ReturnType<typeof setTimeout> | null>(null)

  const onChangeBugdet = (value: number | null) => {
    userProfileActions.changeBudget(value || 0)
    updateProfile()
  }

  const updateProfile = () => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current)
    }
    timeoutHandler.current = setTimeout(() => {
      userProfileActions.updateUserProfile()
      onSave(true)
    }, 1000);
  }

  const onSave = (success: boolean) => {
    if (success) {
      messageApi.success("Profile saved.")
    } else {
      messageApi.error("Couldn't save profile.")
    }
  }

  return (
    <Form
      name="budget"
      layout="vertical"
      requiredMark={false}
      initialValues={{ budget: userProfile?.budget || 0 }}
    >
      <Form.Item
        name="budget"
        label="Weekly budget for group"
        rules={[
          {
            required: true,
            message: 'Please enter a number.',
          },
        ]}
      >
        <InputNumber
          prefix="$"
          style={{ maxWidth: "80px"}}
          onChange={onChangeBugdet}
          className="width-full"
        />
      </Form.Item>
    </Form>
  )
}

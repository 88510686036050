import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Affix, Button, List, Typography, Card, Row, Col } from "antd";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import { useRecipesActions } from '../../actions/recipes_actions';
import { RecipeStatus } from '../../atoms/recipes_atom';
import { UserGroupRecipesQueryID } from '../../atoms/recipes_atom';

export default function RecipeDoneView() {

  const navigate = useNavigate();
  const params = useParams();
  const setUserGroupRecipesQueryID = useSetRecoilState(UserGroupRecipesQueryID)

  const recipesAction = useRecipesActions()
  const recipeId = String(params.id!)

  function goToMenu() {
    navigate("/")
  }

  const { RiveComponent } = useRive({
    src: "https://public.rive.app/community/runtime-files/5103-10277-handshake.riv",
    // Be sure to specify the correct state machine (or animation) name
    // stateMachines: "Motion",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.ScaleDown, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center
    }),
    autoplay: true
  });

  useEffect(() => {
    if (!recipeId) { return }
    recipesAction.updateRecipeStatus(recipeId, RecipeStatus.finished).catch()
  }, [recipeId])

  useEffect(() => {
    setUserGroupRecipesQueryID(id => id + 1)
  }, [])

  return (
    <div className='view-container'>
      <Typography.Title level={1}>
        Recipe Done!
      </Typography.Title>
      <div className="width-full" style={{ height: "450px" }}>
        <RiveComponent />
      </div>
      <Affix offsetBottom={0}>
        <div className="actionbar">
          <Button
            onClick={() => navigate(`/recipe/${recipeId}/review/cooking`)}
            shape="round"
            size="large"
            type="primary"
          >
            Review Recipe
          </Button>
          <div className="flex-break"></div>
          <Button onClick={() => navigate('/')} type="link" shape="round">
            Back to Recipes
          </Button>
        </div>
      </Affix>
    </div>
  );
}
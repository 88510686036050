import { Typography } from "antd"
import { useRecoilState, useRecoilCallback} from "recoil"

import { Allergies, Allergy, userProfileAtom } from "../../atoms/user_profile_atom"
import { shouldReloadRepicesAtom } from "../../atoms/recipes_atom"
import { useUserProfileActions } from "../../actions/user_profile_actions"

import { ToggleButton } from "../choiceui/ToggleButton.Ant"
import { MultipleChoice } from "../choiceui/MultipleChoice"
import {Option, Options, Choice, Choices} from "../../components/choiceui/types";

type AllergiesFormProps = {
  shouldUpdateDB?: boolean
}

export default function AllergiesForm(props: AllergiesFormProps) {
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom)
  const [shouldReload, setShouldReload] = useRecoilState(
    shouldReloadRepicesAtom
  )
  const userProfileActions = useUserProfileActions()

  const options = [
    { label: "None", value: undefined },
    { label: "Milk", value: Allergies.Milk },
    { label: "Peanuts", value: Allergies.Peanuts },
    { label: "Tree nuts", value: Allergies.Treenuts },
    { label: "Wheat", value: Allergies.Wheat },
    { label: "Sesame", value: Allergies.Sesame },
    { label: "Fish", value: Allergies.Fish },
    { label: "Eggs", value: Allergies.Eggs },
    { label: "Shellfish", value: Allergies.Shellfish },
    { label: "Soybeans", value: Allergies.Soybean },
  ]

  function optionToValue(option: Option) {
    return option.value
  }
  function valueInUserAllergies (value: any) {
    return userProfile?.allergies && userProfile?.allergies.findIndex((allergy: Allergy) => allergy.name === value) > -1 ? true : false
  }
  const choices = new Set(options.map(optionToValue).filter(valueInUserAllergies))

  function handleChange (choices: Choices) {
    const resetChoices = [...choices].some((choice: Choice) => {
      return choice === undefined
    })
    if (resetChoices) {
      userProfileActions.resetAllergies()
    } else {
      //TODO: deselect "None" choice here
      userProfileActions.setAllergies(choices)
    }
    
    saveChanges()
  }

  //TODO: this is different from restrictions form. Which one is the right way to do this?
  const saveChanges = useRecoilCallback(({ snapshot }) => async () => {
    const _ = await snapshot.getPromise(userProfileAtom)
    setShouldReload(true)
    if (props.shouldUpdateDB) {
      userProfileActions.updateUserProfile()
    }
  }, []);

  return (
    <>
      <MultipleChoice
        legend={
          <span className="screen-reader-only">Allergies</span>
        }
        onChange={handleChange}
        options={options}
        value={choices}
        renderOptionLabel={(label, checked, i) => {
          return (
            // TODO: label gets wrapped in a button, this needs to be a visualisation of a toggle, not an actual button.
            <ToggleButton key={i} pressed={checked}>
              {label}
            </ToggleButton>
          )
        }}
      >
      </MultipleChoice>
    </>
  )
}

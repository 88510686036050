import { useState } from 'react';
import { Button, Card, Col, Row, message } from 'antd';
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom';

import { sessionState } from '../../atoms/session'
import { useUserProfileActions } from '../../actions/user_profile_actions';
import { UserGroupsQueryID } from '../../atoms/user_group_atom';

import AllergiesView from "../../components/onboarding/allergies_view";
import BirthdayView from '../../components/onboarding/birthday_view';
import CreateAccountView from '../../components/onboarding/create_account_view';
import DietaryRestrictionsView from '../../components/onboarding/dietary_restrictions_view';
import NicknameView from '../../components/onboarding/nickname_view';
import PreferedTeaserRecipeView from '../../components/onboarding/prefered_teaser_recipe_view';

const SignupView = () => {

  const NUMBER_OF_STEPS = 7
  const ALLERGIES_STEP = 2
  const DIETARY_RESTRICTIONS_STEP = 3
  const BIRTHDAY_STEP = 4
  const NICKNAME_STEP = 5
  const PREFERED_TEASER_RECIPE_STEP = 6
  const CREATE_ACCOUNT_STEP = 7

  const userProfileActions = useUserProfileActions()
  const session = useRecoilValue(sessionState)
  const [currentStep, setCurrentStep] = useState(ALLERGIES_STEP)
  const setUserGroupsQueryID = useSetRecoilState(UserGroupsQueryID)

  // TODO: save currentStep to local_storage to continue signup flow 

  const navigate = useNavigate();

  const forward = () => {
    if (nextButtonDisabled()) { return }
    if (currentStep === NUMBER_OF_STEPS) { return }
    // Navigate to main view if we have session already
    if (currentStep === PREFERED_TEASER_RECIPE_STEP && session) {
      userProfileActions.updateUserProfile().then(() => {
        setUserGroupsQueryID(id => id + 1)
        navigate('/')
      })
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const backwards = () => {
    if (currentStep === ALLERGIES_STEP) { navigate("/") }
    setCurrentStep(currentStep - 1)
  }

  const nextButtonDisabled = () => {
    if (currentStep === BIRTHDAY_STEP && 
      (!userProfileActions.getBirthDayDateValue("day") ||
      !userProfileActions.getBirthDayDateValue("month") ||
      !userProfileActions.getBirthDayDateValue("year")))
      {
      return true
    } else if (currentStep === NICKNAME_STEP && userProfileActions.getNickname() === undefined) {
      return true
    }
    return false
  }

  return (
    <div className='view-container'>
      <Row gutter={[8, 24]} align="middle">
        <Col span={24}>
          {currentStep === ALLERGIES_STEP && (<AllergiesView />)}
          {currentStep === DIETARY_RESTRICTIONS_STEP && (<DietaryRestrictionsView />)}
          {currentStep === BIRTHDAY_STEP && (<BirthdayView />)}
          {currentStep === NICKNAME_STEP && (<NicknameView />)}
          {currentStep === PREFERED_TEASER_RECIPE_STEP && (<PreferedTeaserRecipeView />)}
          {currentStep === CREATE_ACCOUNT_STEP && (<CreateAccountView />)}
        </Col>
        {currentStep !== CREATE_ACCOUNT_STEP && (
          <>
            <Col span="8">
              {currentStep > 1 && (
                <Button type="link" shape="round" htmlType="submit" onClick={() => backwards()}>
                  &larr; Back
                </Button>
              )}
            </Col>
            <Col span="8">
              <Button style={{ display: 'block', width: '100%', marginLeft: 'auto', marginRight: 'auto', paddingLeft: '0', paddingRight: '0' }} type="primary" shape="round" size="large" htmlType="submit" onClick={() => forward()}>
                Next
              </Button>
            </Col>
            <Col span="8">
            </Col>
          </>
        )}
      </Row>
    </div>
  )
}

export default SignupView;
import { useRecoilState, useSetRecoilState } from 'recoil'
import { LifeLongClient } from '../axios'
import {
  recipesAtom
} from "../atoms/recipes_atom";
import { eaterRecipeReviewAtom } from '../atoms/eater_recipe_review';
import { ChefRecipeReviewParameters } from './recipes_review_actions';

export enum ReasonNotToEat {
  DidntLikeTaste = "didnt_like_taste",
  DidntLikePresentation = "didnt_like_presentation",
  DidntLikeIngredients = "didnt_like_ingredients",
  Other = "other"
}

export type EaterRecipeReviewParameters = {
  open_text_reason?: string,
  recipe_id: string,
  reason_not_to_eat_again?: ReasonNotToEat,
  skipped?: boolean,
  would_eat_again?: boolean,
}

function useEaterRecipeReviewActions() {

  const client = LifeLongClient()
  const [recipes, setRecipes] = useRecoilState(recipesAtom)
  const setEaterRecipeReview = useSetRecoilState(eaterRecipeReviewAtom)

  async function fetchReview(recipeId: string) {
    const { data } = await client.client().get(`/recipes/${recipeId}/eater_recipe_review`)
    setEaterRecipeReview(data)
  }

  async function updateReview(params: ChefRecipeReviewParameters) {
    const { data } = await client.client().patch(`/recipes/${params.recipe_id}/eater_recipe_review`, params)
    const newRecipes = recipes.map(recipe => {
      if (recipe.id === params.recipe_id) {
        const newRecipe = { ... recipe }
        newRecipe.eater_review = data
        return newRecipe
      }
      return recipe
    })
    setRecipes(newRecipes)
  }

  async function publishReview(recipeId: string) {
    const { data } = await client.client().patch(`/recipes/${recipeId}/eater_recipe_review/publish`)
    const newRecipes = recipes.map(recipe => {
      if (recipe.id === recipeId) {
        const newRecipe = { ... recipe }
        newRecipe.eater_review = data
        return newRecipe
      }
      return recipe
    })
    setRecipes(newRecipes)
  }

  return {
    fetchReview,
    updateReview,
    publishReview
  }
}

export { useEaterRecipeReviewActions }
import {
  Checkbox,
  Input,
  Form,
  Collapse,
  Button,
  List,
  Select,
  Space,
  Typography,
  Row,
  Col,
} from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import type { SelectProps } from 'antd';

import { useNavigate, useParams } from "react-router-dom"

import Navbar from "../../components/Navbar"
import AvatarRound from "../../components/group/avatar_round"
// import { useShoppingListActions } from "../../actions/shopping_list_actions"
import { useSetRecoilState, useRecoilState, useRecoilValueLoadable } from "recoil"
import {
  ShoppingList,
  ShoppingListItem,
  ShoppingListItemVisibility,
  shoppingListsState
  // sortedShoppingListSelector,
} from "../../atoms/shopping_list_atom"
import { shoppingListsAtom } from "../../atoms/shopping_list_atom"
import { userGroupRecipesQuery } from "../../atoms/recipes_atom"
import { shoppingListActions } from "../../actions/shopping_list_actions";
import { shoppingListItemsActions } from "../../actions/shopping_list_items_actions";
import { User } from "../../atoms/user_group_atom"

export default function RecipeView() {
  const { id } = useParams()

  const groupId = String(id)
  const [shoppingLists, setShoppingLists] = useRecoilState(shoppingListsState);
  const recipes = useRecoilValueLoadable(userGroupRecipesQuery({ groupId }))
  // const shoppingLists = useRecoilValueLoadable(shoppingListsAtom(id!))
  // const resetShoppingLists = useRecoilRefresher_UNSTABLE(shoppingListsAtom(id!))

  // const shoppingListActions = useShoppingListActions()
  const navigate = useNavigate()
  // const shoppingList = useRecoilValue(sortedShoppingListSelector)

  // const [newGrocery, setNewGrocery] = useState("")

  const onCreateShoppingList = (values: any) => {
    const recipeIds = values['recipes']
    let items: string[] = []
    if (recipeIds && recipes.state === 'hasValue') {
      const selectedRecipes = recipes.contents.filter(r => recipeIds.includes(r.id))
      selectedRecipes.forEach(recipe => {
        items = [
          ...items,
          ...Object.keys(recipe.data.ingredients),
        ];
      });
    }
    shoppingListActions().create(id!, items)
      .then((response) => {
        setShoppingLists([...shoppingLists, response])
      })
  }

  const onCreateShoppingItem = (values: any) => {
    console.log('onCreateShoppingItem', values)
    const title = values['crocery_title']
    const shoppingListId = values['shopping_list_id']
    shoppingListItemsActions(groupId).create(groupId, shoppingListId, title)
    .then(data => {
      setShoppingLists(
        shoppingLists.map((list: ShoppingList) => {
          if (list.id === shoppingListId) {
            return {
              ...list,
              shopping_list_items: list.shopping_list_items.concat([data]),
            };
          }
          return list;
        })
      )
    })
  }  

  const onUpdateItem = async (shoppingList: ShoppingList, item: ShoppingListItem) => {
    const state = item.state === ShoppingListItemVisibility.Bought ? ShoppingListItemVisibility.NotBought : ShoppingListItemVisibility.Bought
    console.log('state', state)
    shoppingListItemsActions(groupId)
      .update(groupId, shoppingList.id, item.id, state)
      .then(data => {
        setShoppingLists(
          shoppingLists.map((list: ShoppingList) => {
            if (list.id === shoppingList.id) {
              return {
                ...list,
                shopping_list_items: list.shopping_list_items.map((old_item) => {
                  if (old_item.id === item.id) {
                    return { ...old_item, state };
                  }
                  return old_item;
                }),
              };
            }
            return list;
          })
        )
      })
  }

  // const onRemoveItem = async (id: number) => {
  //   await shoppingListActions.removeShoppingListItem(id)
  // }

  const goToMenu = () => {
    navigate("/")
  }

  const recipeOptions = () => {
    if (recipes.state === 'hasValue') {
      return recipes.contents.filter(recipe => recipe.data).map(recipe => { return { label: recipe.data.recipe, value: recipe.id } })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const shoppingLists = await shoppingListActions().index(groupId);
      setShoppingLists(shoppingLists);
    };

    fetchData();
  }, [groupId, setShoppingLists]);

  return (
    <div className="view-container">
      <Navbar>
        <Button type="link" shape="round" size="small" onClick={goToMenu}>
          ← Recipes
        </Button>
      </Navbar>
      <Typography.Title level={1} className="view-title">
        Groceries
      </Typography.Title>
      <Form
        name="shoppingList"
        onFinish={onCreateShoppingList}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item name="recipes">
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select recipes"
            options={recipeOptions()}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">
            Create shopping list
          </Button>
        </Form.Item>
      </Form>
      <Collapse
        items={shoppingLists.map((shoppingList: ShoppingList) => {
          return {
            key: shoppingList.id,
            label: (
              <Typography.Title level={4}>{shoppingList.owner.user_profile.nick_name}'s shopping list</Typography.Title>
            ),
            children: (
              <>
                <List
                  itemLayout="horizontal"
                  dataSource={shoppingList.shopping_list_items}
                  renderItem={(item, index) => (
                    <List.Item>
                      <Checkbox
                        checked={item.state === 'bought'}
                        onChange={() => onUpdateItem(shoppingList, item)}
                      >
                        <Row align="middle" gutter={[8, 8]}>
                          <Col>{item.title}</Col>
                        </Row>
                      </Checkbox>
                    </List.Item>
                  )}
                />
                <Form
                  name="customGrocery"
                  onFinish={onCreateShoppingItem}
                  layout="vertical"
                  requiredMark={false}
                >
                  <Row gutter={[8, 8]} align="bottom">
                    <Col flex="1 0 auto">
                      <Form.Item
                        name="crocery_title"
                        label={
                          <Typography.Title level={4}>
                            Something else?
                          </Typography.Title>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please write a grocery item first.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        initialValue={shoppingList.id}
                        name="shopping_list_id"
                        hidden
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col flex="0 0 min-content">
                      <Form.Item>
                        <Button htmlType="submit">
                          Add Item
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </>
            ),
          }
        })}
      />
      {/* <Typography.Title level={1} className="view-title">
        Groceries
      </Typography.Title>
      <Collapse
        items={shoppingList?.recipe_lists.map((list: ShoppingListElement, id: number) => {
          return {
            key: id,
            label: (
              <Typography.Title level={4}>{list.recipe.data.emoji}&nbsp;&nbsp;{list.recipe.data.recipe}</Typography.Title>
            ),
            children: (
              <List
                itemLayout="horizontal"
                dataSource={list.items.map(
                  (el: ShoppingListItem) => el
                )}
                renderItem={(item, index) => (
                  <List.Item>
                    <Checkbox
                      checked={isChecked(item)}
                      onChange={() => onUpdateItem(item)}
                    >
                      <Row align="middle" gutter={[8, 8]}>
                        <Col>{item.title}</Col>
                      </Row>
                    </Checkbox>
                  </List.Item>
                )}
              />
            ),
          }
        })}
      />

      <Form
        name="customGrocery"
        onFinish={onAddItem}
        layout="vertical"
        requiredMark={false}
      >
        <Row gutter={[8, 8]} align="bottom">
          <Col flex="1 0 auto">
            <Form.Item
              name="newGrocery"
              label={
                <Typography.Title level={4}>
                  Something else?
                </Typography.Title>
              }
              rules={[
                {
                  required: true,
                  message: "Please write a grocery item first.",
                },
              ]}
            >
              <Input
                id="custom-grocery"
                value={newGrocery}
                onChange={(e: any) => setNewGrocery(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col flex="0 0 min-content">
            <Form.Item>
              <Button htmlType="submit">
                Add Item
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form> */}

      {/* <Space direction="vertical" className="width-full">
        {shoppingList?.user_lists
          .filter((list: ShoppingListElement) => list.items.length > 0)
          .map((list: ShoppingListElement, id: number) => (
            <Collapse
              defaultActiveKey={id}
              key={id}
              expandIcon={({ isActive }) => <></>}
              items={[
                {
                  key: id,
                  label: (
                    <Space align="center">
                      <AvatarRound
                        nickname={(list.user as User).user_profile.nick_name || ""}
                        initials={(list.user as User).user_profile.nick_name || ""
                          .split(" ")
                          .map((n: string) => n[0])
                          .join("")}
                        email={(list.user as User).email}
                      />
                      {(list.user as User).user_profile.nick_name}
                    </Space>
                  ),
                  children: (
                    <>
                      <List
                        size="small"
                        itemLayout="horizontal"
                        dataSource={list.items}
                        renderItem={(item: ShoppingListItem) => (
                          <List.Item
                            actions={[
                              <Button
                                type="text"
                                size="small"
                                icon={<CloseOutlined />}
                                onClick={() => onRemoveItem(item.id)}
                              ></Button>,
                            ]}
                          >
                            <Checkbox
                              checked={isChecked(item)}
                              onChange={() => onUpdateItem(item)}
                            >
                              {item.title}
                            </Checkbox>
                          </List.Item>
                        )}
                      />
                    </>
                  )
                }
              ]}
            >
            </Collapse>
          ))
        }
      </Space> */}
    </div>
  )
}

import { atom, selector, selectorFamily } from 'recoil'
import { User, UserGroup } from './user_group_atom';
import { invitationsActions } from '../actions/invitations_actions';

export enum InvitationStatus {
  pending = "pending",
  accepted = "accepted",
  declined = "declined"
}

export type Invitation = {
  id: number;
  status: InvitationStatus;
  sender: User;
  receiver: User;
  user_group: UserGroup;
}

export type Invitations = {
  sent_invitations: Invitation[];
  received_invitations: Invitation[];
}

export const invitationsAtom = selector<Invitation[]>({
  key: 'groupInvitationsAtomKey',
  get: async () => {
    return await invitationsActions().received_invitations()
  }
})

export const groupInvitationsAtom = selectorFamily<Invitations, number>({
  key: 'groupInvitationsAtomKey',
  get: groupID => async () => {
    return await invitationsActions().index(groupID)
  }
})
import { ShoppingListItemVisibility } from '../atoms/shopping_list_atom';
import { LifeLongClient } from '../axios';

export const shoppingListItemsActions = (groupId: string) => {
  const client = LifeLongClient();

  async function create(
    user_group_id: string,
    shopping_list_id: string,
    title: string
  ) {
    const { data } = await client.client().post(
      `/user_groups/${user_group_id}/shopping_lists/${shopping_list_id}/shopping_list_items/`,
      { title }
    );

    return data;
  }

  async function update(
    user_group_id: string,
    shopping_list_id: string,
    shopping_list_item_id: string,
    state: ShoppingListItemVisibility
  ) {
    const { data } = await client.client().patch(
      `/user_groups/${user_group_id}/shopping_lists/${shopping_list_id}/shopping_list_items/${shopping_list_item_id}`,
      { state }
    );

    return data;
  }

  return {
    create,
    update,
  };
};

import {useState, ReactNode, ReactElement} from "react"

import type {OptionLabel, Option, Options, Choice} from './types.js'

export interface ChooseOneProps {
  legend?: ReactNode //The question
  children?: ReactNode //Any additional content that is needed for the question
  options: Options //
  value?: Choice //For pre-set choice
  onChange: (choice: Choice) => void
  renderOptionLabel: (label: OptionLabel, checked: boolean, i: number) => ReactElement
}

export function ChooseOne (props: ChooseOneProps) {

  const options = props.options
  const renderOptionLabel = props.renderOptionLabel
  const [choice, setChoice] = useState(props.value || "")
  const onChange = props.onChange

  function handleClick (value: Choice) {
    setChoice(value)
    onChange(value)
  }
  function isChecked (option: Option) {
    return option.value === choice
  }

  //TODO: renderOptions is identical between ChooseOne and MultipleChoice, maybe make some common renderOptions utility component?
  // TODO: This should work in such a way that if you pass in a button, you can render the buttons yourself, but if you pass in anything else, it gets baked inside a button.
  function renderOptions (options: Options) {
    return options.map((option, i) => {
      const checked = isChecked(option)
      const optionElement = renderOptionLabel(option.label, checked, i)

      return (
        <button
          className={"transparent vuo-option " + (optionElement.props.className ? optionElement.props.className : "")}
          aria-pressed={checked ? "true" : "false"}
          key={option.value}
          value={option.value}
          onClick={() => {
            handleClick(option.value)
          }}
        >
          {optionElement}
        </button>
      )
    })
  }

  return (
    <fieldset className="vuo-question vuo-chooseone">
      {props.legend && (
        <legend>
          {props.legend}
        </legend>
      )}
      {props.children}
      <div className="vuo-options">
        {renderOptions(options)}
      </div>
    </fieldset>
  )
}
import { Space, Button, Tooltip, Tag, Typography, Card, Row, Col, Avatar } from "antd";
import { useRecoilState } from "recoil";
import { ClockCircleOutlined } from '@ant-design/icons';
import { GroupRecipe } from "../../atoms/recipes_atom";

interface RecipeRequirementsCardProps {
  recipe: GroupRecipe
}

export default function RecipeRequirementsCard({ recipe }: RecipeRequirementsCardProps) {

  return (
    <Card type="inner" className="recipe-requirements-card">
      <Typography.Title level={4} className="mgt-0">
        Cookware and Utensils
      </Typography.Title>
      <Space wrap>
        {recipe.data.needed_tools.map(tool => (
          <Tag key={tool} color="volcano">{tool}</Tag>
        ))}
      </Space>
      <Typography.Title level={4}>
        Ingredients
      </Typography.Title>
      {Object.keys(recipe.data.ingredients).map(key => {
        //Parse ingredient according to how we get it from the prompt right now. This data should come pre sanitized to the front end, but this will improve the text appearance with the current data.
        const name = key.replaceAll("_", " ")
        const amount = recipe.data.ingredients[key].split(",")[0].trim()
        const how = recipe.data.ingredients[key].split(",")[1]?.trim() //Sorry, starting to look like some regex.
        return (
          <Row style={{marginBlockEnd: "8px", paddingBlockEnd: "8px", borderBlockEnd: "1px solid rgba(0,0,0,.1)"}}>
            <Col xs={16} md={8} style={{textTransform: "capitalize"}}>
              <b>{name}</b>
            </Col>
            <Col xs={8} md={8}>
              {amount}
            </Col>
            <Col xs={24} md={8}>
              {how && (
                <small>
                  {how}
                </small>
              )}
            </Col>
          </Row>
        )
      })}
    </Card>
  );
}
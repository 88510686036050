import { Avatar } from "antd";
import { useRecoilValue, useRecoilValueLoadable } from "recoil"

import { selectedGroupIdState, userGroupQuery } from "../../atoms/user_group_atom"

import AvatarRound from "../../components/group/avatar_round"

interface MembersProps {
  type?: string,
}

export default function Members(props: MembersProps) {

  const selectedGroupId = useRecoilValue(selectedGroupIdState)
  const userGroup = useRecoilValueLoadable(userGroupQuery(selectedGroupId))

  return (
    <>
      {userGroup.state === 'hasValue' && (
        <Avatar.Group>
          {userGroup.contents && (
            <AvatarRound key={userGroup.contents.owner.email}
              nickname={userGroup.contents.owner.user_profile?.nick_name || userGroup.contents.owner.email}
              initials={userGroup.contents.owner.user_profile?.nick_name?.substring(0, 2) || userGroup.contents.owner.email.substring(0, 2)}
              email={userGroup.contents.owner.email} />
          )}
          {userGroup.contents?.members.map((member) => (
            <AvatarRound
              key={member.email}
              nickname={member.user_profile.nick_name || member.email}
              initials={member.user_profile.nick_name?.substring(0, 2) || member.email.substring(0, 2)}
              email={member.email} />
          ))}
        </Avatar.Group>
      )}
    </>
  );
}
import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import { Session } from './atoms/session'

export function LifeLongClient() {

  const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const session = getSessionFromLocalStorage();
    if (config.headers && session) {
      config.headers['Authorization'] = session.access_token
    }
    return config
  }

  const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error)
  }

  function client() {
    const instance = axios.create({ baseURL: baseUrl() })
    instance.interceptors.request.use(onRequest, onRequestError)
    instance.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if ([401, 403].includes(error.response.status)) {
        setSessionToNullInLocalStorage()
      }
      return Promise.reject(error);
    });
    return instance
  }

  function baseUrl() {
    if (!process.env.REACT_APP_VUO_LIFELONG_API_DOMAIN) {
      return `${window.location.protocol}//localhost:80/api`
    } else {
      return `${process.env.REACT_APP_VUO_LIFELONG_API_DOMAIN}${process.env.REACT_APP_VUO_LIFELONG_API_BASE}`
    }    
  }

  function getSessionFromLocalStorage(): Session | null {
    const savedValue = localStorage.getItem("sessionStorageKey");
    return savedValue ? JSON.parse(savedValue) : null;
  }  

  function setSessionToNullInLocalStorage() {
    localStorage.setItem("sessionStorageKey", JSON.stringify(null));
  }
  
  return { client }
}    
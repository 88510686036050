import { useRecoilState, useRecoilCallback } from "recoil"
import { LifeLongClient } from "../axios"
import {
  Allergy,
  DietaryRestriction,
  userProfileAtom,
} from "../atoms/user_profile_atom"
import {Option, Options, Choice, Choices} from "../components/choiceui/types";

function useUserProfileActions() {
  const client = LifeLongClient()
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom)

  async function fetchUserProfile() {
    const { data } = await client.client().get(`/my_profile/`)
    setUserProfile(data)
    return data
  }

  async function saveUserProfile() {
    const latestUserProfile = await getUserProfile()
    const { data } = await client
      .client()
      .post(`/my_profile/`, latestUserProfile)
    setUserProfile(data)
    return data
  }

  async function updateUserProfile() {
    let latestUserProfile = await getUserProfile()
    const { data } = await client
      .client()
      .put(`/my_profile/`, latestUserProfile)
    setUserProfile(data)
    return data
  }

  async function invitePerson(email: string) {
    const { data } = await client
      .client()
      .post(`/invites/`, { email: email })
    return data
  }

  function getBirthDayDateValue(value: "day" | "month" | "year"): string {
    if (!userProfile?.birthday) {
      return ""
    }
    const [year, month, day] = userProfile?.birthday?.split("-").map((v) => v)

    switch (value) {
      case "day":
        return day
      case "month":
        return month
      case "year":
        return year
      default:
        throw new Error(
          "Invalid value parameter. It should be 'day', 'month', or 'year'."
        )
    }
  }

  function getBirthDate(): string | undefined {
    return userProfile?.birthday
  }

  function getNickname(): string | undefined {
    return userProfile?.nick_name
  }

  async function changeBirthday(day: string, month: string, year: string, save: boolean = false) {
    setUserProfile({ ...userProfile, birthday: `${day}-${month}-${year}` })
    if (save) {
      const { data } = await client.client().post(`/my_profile/`, { profile: { ...userProfile, birthday: `${day}-${month}-${year}` } })
      setUserProfile(data["data"])
    }
  }

  function changeBudget(budget: number) {
    setUserProfile({ ...userProfile, budget: budget })
  }

  function changeNickname(nickName: string) {
    setUserProfile({ ...userProfile, nick_name: nickName })
  }

  //TODO: not sure about the types here, Should this be some allergies type coming in here? The typing kinda changes in the allergies_form like incoming data is Allergies and outgoing it Choices.
  function setAllergies (allergies: Choices) {
    setUserProfile({
      ...userProfile,
      //TODO: Spreading Set to Array here. Choices type is a Set, should maybe restrictions and allergies in UserProfile type be sets, because we do not want duplicate entries there? That would also align them to alway be the same type as Choices.
      allergies: [...allergies],
    })
  }

  function resetAllergies() {
    setUserProfile({ ...userProfile, allergies: [] })
  }

  //TODO: not sure about the types here, Should this be some allergies type coming in here? The typing kinda changes in the allergies_form like incoming data is Allergies and outgoing it Choices.
  function setDietaryRestrictions (restrictions: Choices) {
    setUserProfile({
      ...userProfile,
      //TODO: Spreading Set to Array here. Choices type is a Set, should maybe restrictions and allergies in UserProfile type be sets, because we do not want duplicate entries there? That would also align them to alway be the same type as Choices.
      dietary_restrictions: [...restrictions],
    })
  }

  function resetDietaryRestrictions() {
    setUserProfile({ ...userProfile, dietary_restrictions: [] })
  }

  function removeUserProfile() {
    setUserProfile(null)
  }

  const getUserProfile = useRecoilCallback(({ snapshot }) => async () => {
    const profile = await snapshot.getPromise(userProfileAtom)
    return profile
  }, []);

  return {
    changeBirthday,
    changeBudget,
    changeNickname,
    fetchUserProfile,
    getBirthDate,
    getBirthDayDateValue,
    getNickname,
    removeUserProfile,
    setAllergies,
    resetAllergies,
    setDietaryRestrictions,
    resetDietaryRestrictions,
    saveUserProfile,
    updateUserProfile,
    invitePerson,
  }
}

export { useUserProfileActions }

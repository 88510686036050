import { Tooltip, Avatar } from "antd";

interface AvatarProps {
  nickname: string,
  email: string,
  initials: string,
  color?: string
}

export default function AvatarRound(user: AvatarProps) {

  // TODO: Probably refactor so we have one avatar component that can be a round thumbnail or a large one with full nickname
  return (
    <Tooltip placement="bottom" title={user.nickname}>
      <Avatar
        style={{
          // TODO: Maybe generate a color for each user so each avatar bubble is more unique than just initials.
          backgroundColor: user.color ? user.color : 'green',
          verticalAlign: 'middle',
        }}
        size={32}
        gap={6}>
        {user.initials}
      </Avatar>
    </Tooltip>
  )
}
import { useNavigate, useParams } from "react-router-dom"
import { Affix, Progress, Button, Typography, Spin } from "antd";

import { userGroupRecipeQuery } from '../../atoms/recipes_atom';
import { useRecoilValueLoadable } from 'recoil';
import RecipeDirectionInstructionCard from "../../components/recipe/recipe_direction_instruction_card";
import Navbar from "../../components/Navbar"

export default function RecipeDirectionView() {

  const navigate = useNavigate()
  const params = useParams();

  const recipe = useRecoilValueLoadable(userGroupRecipeQuery(String(params.id!)))
  const stepNumber = parseInt(params.step!)

  const goToRecipe = (recipeId: string) => {
    navigate(`/recipe/${recipeId}/`)
  }

  const goToNextStep = (recipeId: string) => {
    navigate(`/recipe/${recipeId}/step/${stepNumber + 1}`)
  }

  const goToPrevStep = () => {
    navigate(-1)
  }

  const reachedLastStep = () => {
    if (recipe.state !== 'hasValue') { return true }
    return (stepNumber >= (recipe.contents.data.preparation_steps.length))
  }

  return (
    <>
      {recipe.state !== 'hasValue' && (
        <Spin />
      )}
      {recipe.state === 'hasValue' && (
        <div className='view-container'>
          <Navbar>
            <Button type="link" shape="round" size="small" onClick={() => goToRecipe(recipe.contents.id)}>
              ← Recipe
            </Button>
          </Navbar>
          <hgroup>
            <Typography.Title level={1}>
              Step {stepNumber}
              {/* of {recipe.data.preparation_steps.length} */}
            </Typography.Title>
            <Typography.Title level={4}>
              {recipe.contents.data.recipe}
            </Typography.Title>
          </hgroup>
          <Progress percent={stepNumber / recipe.contents.data.preparation_steps.length * 100} showInfo={false} />
          <RecipeDirectionInstructionCard step={recipe.contents.data.preparation_steps[stepNumber - 1]} />
          <Affix offsetBottom={0}>
            <div className="actionbar">
              <Button onClick={goToPrevStep} type="primary" size="large" shape="round" className="width-full" style={{ maxWidth: "200px" }}>
                ←
              </Button>
              {!reachedLastStep() && (
                <Button onClick={() => goToNextStep(recipe.contents.id)} type="primary" size="large" shape="round" className="width-full" style={{ maxWidth: "200px" }}>
                  →
                </Button>
              )}
              {reachedLastStep() && (
                <Button onClick={() => navigate(`/recipe/${recipe.contents.id}/done/`)} type="primary" size="large" shape="round" className="width-full" style={{ maxWidth: "200px" }}>
                  Done!
                </Button>
              )}
            </div>
          </Affix>
        </div>
      )}
    </>
  )
}
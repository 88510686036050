import {Affix, Button, Typography} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";

import {useRecoilValueLoadable} from "recoil";
import {useChefRecipeReviewActions} from "../../actions/chef_recipes_review_actions";
import {RecipeThumb} from "../../components/recipe/RecipeThumb";
import {YesNo} from "../../components/choiceui/YesNo"
import {ChefRecipeReviewParameters} from "../../actions/recipes_review_actions";
import {userGroupRecipeQuery} from "../../atoms/recipes_atom";
import type {Option, Options, Choice, Choices} from '../../components/choiceui/types.js'
import Navbar from "../../components/Navbar"

export default function RecipeReviewCookingView() {
  const navigate = useNavigate()
  const params = useParams()
  const recipeId = String(params.id!)

  const chefRecipeReviewActions = useChefRecipeReviewActions()
  const recipeQuery = useRecoilValueLoadable(userGroupRecipeQuery(recipeId))

  const goToMenu = () => {
    navigate('/')
  }

  function handleClick (choice: Choice) {
    const params: ChefRecipeReviewParameters = {
      recipe_id: recipeId,
      would_cook_again: choice
    }
    chefRecipeReviewActions.updateChefRecipeReview(params).then(() => {
      if (choice === false) {
        navigate(`/recipe/${recipeId}/review/cooking_feedback`)
      } else {
        if (recipeQuery.state === 'hasValue') {
          if (recipeQuery.contents.fill_in_seat) {
            navigate(`/recipe/${recipeId}/review/skill`)
          } else {
            chefRecipeReviewActions.publishChefRecipeReview(recipeId).then(() => {
              navigate('/recipe/review/thanks')
            })
          }
        }
      }
    })    
  }

  return (
    <div className='view-container'>
      <Navbar>
        <Button type="link" shape="round" size="small" onClick={goToMenu}>
          ← Back to Recipes
        </Button>
      </Navbar>
      <YesNo
        legend={
          <Typography.Title level={2} className="view-title">
            Would You Cook This Again?
          </Typography.Title>
        }
        onClick={handleClick}
        no={{
          label: <Button type="primary" size="large" shape="round" htmlType="submit">👎 No</Button>,
          value: false
        }
        }
        yes={{
          label: <Button type="primary" size="large" shape="round" htmlType="submit">👍 Yes</Button>,
          value: true
        }
        }
      >
        {recipeQuery.state === 'hasValue' && (
          <RecipeThumb
            emoji={recipeQuery.contents.data.emoji}
            title={recipeQuery.contents.data.recipe}
          />
        )}
      </YesNo>
    </div>
  )
}
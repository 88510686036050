import { useRef } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { useNavigate } from "react-router-dom";
import { Button, Typography, message } from "antd"
import { LeftOutlined } from "@ant-design/icons"

import { userProfileAtom } from "../../atoms/user_profile_atom"
import { useUserProfileActions } from "../../actions/user_profile_actions"
import { userDataQuery } from "../../atoms/user_atom";

import Navbar from "../../components/Navbar"
import NicknameForm from "../../components/profile/nickname_form"
import BudgetForm from "../../components/group/budget_form"
import BirthdayForm from "../../components/profile/birthday_form"
import AllergiesForm from "../../components/profile/allergies_form"
import DietaryRestrictionsForm from "../../components/profile/dietary_restrictions_form"

export default function UserProfileView() {

  const [messageApi, messageContextHolder] = message.useMessage();
  const navigate = useNavigate()
  const userProfile = useRecoilValue(userProfileAtom)
  const userProfileActions = useUserProfileActions()
  const timeoutHandler = useRef<ReturnType<typeof setTimeout> | null>(null)
  const userData = useRecoilValueLoadable(userDataQuery)

  const goToMenu = () => {
    navigate('/')
  }

  const updateProfile = () => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current)
    }
    timeoutHandler.current = setTimeout(() => {
      userProfileActions.updateUserProfile()
      onSave(true)
    }, 1000);
  }

  const onSave = (success: boolean) => {
    if (success) {
      messageApi.success("Profile saved.")
    } else {
      messageApi.error("Couldn't save profile.")
    }
  }

  const birthDayDate = new Date(userProfile?.birthday!)
  const todayDate = new Date()
  let age = todayDate.getFullYear() - birthDayDate.getFullYear()
  if (
    todayDate.getMonth() < birthDayDate.getMonth() ||
    (todayDate.getMonth() === birthDayDate.getMonth() &&
      todayDate.getDate() < birthDayDate.getDate())
  ) {
    age--
  }

  return (
    <div className="view-container">
      {messageContextHolder}
      <Navbar>
        <Button type="link" shape="round" size="small" onClick={goToMenu}>
          ← Recipes
        </Button>
      </Navbar>
      <Typography.Title level={1}>
        Profile
      </Typography.Title>

      {userData.state === 'hasValue' && (
        <Typography.Title level={2}>
          {userData.contents.email}
        </Typography.Title>
      )}

      <NicknameForm shouldUpdateDB />

      <Typography.Title level={2}>
        Budget
      </Typography.Title>
      {/* TODO: Weekly budget column here should only be shown to group admin */}
      <BudgetForm />

      <Typography.Title level={2}>
        Birthday
      </Typography.Title>
      <BirthdayForm shouldUpdateDB />

      <Typography.Title level={2}>
        Allergies
      </Typography.Title>
      <AllergiesForm shouldUpdateDB />

      <Typography.Title level={2}>
        Dietary Restrictions
      </Typography.Title>
      <DietaryRestrictionsForm shouldUpdateDB />

    </div>
  )
}

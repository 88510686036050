import { Space, Button, Tooltip, Tag, Typography, Card, Row, Col, Avatar } from "antd";
import { useRecoilState } from "recoil";
import { ClockCircleOutlined } from '@ant-design/icons';
import { PreparationStep } from "../../atoms/recipes_atom";

import AvatarRound from "../../components/group/avatar_round";

interface RecipeDirectionInstructionCardProps {
  step?: PreparationStep
}

export default function RecipeDirectionInstructionCard({step}: RecipeDirectionInstructionCardProps) {

  if (!step) {
    return (
      <Card type="inner" className="recipe-direction-card">
        <Typography.Title>Loading</Typography.Title>
      </Card>
    )
  } {
    //This is goofy
    let [name, ...rest] = step.description ? step.description.split(":") : "".split(":")
    let desc = rest.join(":")
    if (!rest) {
      desc = name //No name found, so name will be the whole string because that's how split function works
      name = ""
    }
    return (
      <Card type="inner" className="recipe-direction-card">
        <div className="flex gap-8 align-items-center">
          <div className="flex-grow-1"></div>
          <AvatarRound nickname={name} email="" initials={name.substring(0,2)} />
        </div>
        <Typography.Paragraph className="ant-typography-lg recipe-direction-title">
          <span className="recipe-direction-emoji">
            {step.emoji && step.emoji}
          </span>
          <span>
            <b>{name}:</b>
            {desc}
          </span>
        </Typography.Paragraph>
        {step.needed_tools && (
          <>
            <Space wrap className="justify-content-center">
              {step.needed_tools.map(tool => (
                <Tag key={tool} color="volcano">
                  {tool}
                </Tag>
              ))}
            </Space>
          </>
        )}
        {step.ingredients && (
          <>
            <Space wrap className="justify-content-center">
              {Object.keys(step.ingredients).map((key) => {
                const name = key.replaceAll("_", " ")
                const amount = step.ingredients![key].replace(",", "")
                return (
                  <Tag key={key} color="green">
                    {amount}
                    &nbsp;
                    {name}
                  </Tag>
                )
              })}
            </Space>
          </>
        )}
      </Card>
    )
  }
}
import { atom } from "recoil"
import { localStorageEffect } from "./local_storage_effect"

export enum FoodGenre {
  Healthy = "healthy",
  Cheap = "cheap",
  Exotic = "exotic",
  Fast = "fast",
}

export enum Allergies {
  Milk = "milk",
  Peanuts = "peanuts",
  Treenuts = "treenuts",
  Wheat = "wheat",
  Sesame = "sesame",
  Fish = "fish",
  Eggs = "eggs",
  Shellfish = "shellfish",
  Soybean = "soybean",
}

export enum DietaryRestrictions {
  LowCarb = "lowcarb",
  Kosher = "kosher",
  LactoseFree = "lactosefree",
  GlutenFree = "glutenfree",
  LowFat = "lowfat",
  Halal = "halal",
  Vegetarian = "vegetarian",
  LowSodium = "lowsodium",
  Pescatarian = "pescatarian",
  Paleo = "paleo",
  Vegan = "vegan",
  Keto = "keto",
}

export type DietaryRestriction = {
  name: DietaryRestrictions
}

export type Allergy = {
  name: Allergies
}

export type UserProfile = {
  allergies?: Allergy[]
  birthday?: string
  dietary_restrictions?: DietaryRestriction[]
  nick_name?: string
  otherAllergy?: string[]
  otherDietaryRestriction?: string[]
  budget?: number
}

export const userProfileAtom = atom<UserProfile | null>({
  key: "userProfileAtomKey",
  default: null,
  effects: [localStorageEffect("UserProfileAtomStorageKey")],
})

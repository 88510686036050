import {useNavigate} from "react-router-dom";
import {useRecoilValueLoadable, useRecoilStateLoadable} from "recoil"
import {useParams} from "react-router-dom";

import {Button, Input, Card, Form, Select, Spin, Avatar, Typography, Divider} from "antd";

import {selectedGroupIdState, userGroupsQuery, UserGroup, userGroupQuery} from "../../atoms/user_group_atom"
import {groupInvitationsAtom} from "../../atoms/invitations_atom"
import {AddMore} from "../../components/choiceui/AddMore.Ant"
import UserGroupForm from "../../components/profile/user_groups_form";
import Navbar from "../../components/Navbar"

import {invitationsActions} from "../../actions/invitations_actions";
import {Choices} from "../../components/choiceui/types";

export default function GroupView() {

  const params = useParams()
  const groupID = Number(params.id)

  const [selectedGroupIdLoadable, setSelectedGroupId] = useRecoilStateLoadable(selectedGroupIdState);
  const userGroup = useRecoilValueLoadable(userGroupQuery(selectedGroupIdLoadable.state === 'hasValue' ? selectedGroupIdLoadable.contents : null))
  const userGroupLoadable = useRecoilValueLoadable(userGroupQuery(groupID))
  const invitations = useRecoilValueLoadable(groupInvitationsAtom(groupID))
  const actions = invitationsActions()
  const navigate = useNavigate()

  const userGroups = useRecoilValueLoadable(userGroupsQuery)

  function handleGroupChange (newGroupId: number) {
    setSelectedGroupId(newGroupId)
  }

  function groupItems () {
    if (userGroups.state !== 'hasValue') { return [] }
    const allGroups = [...userGroups.contents.owned_user_groups, ...userGroups.contents.user_groups]
    return allGroups.map((group: UserGroup) => {
      return {
        value: group.id,
        label: group.name
      }
    })
  }

  function goToMenu () {
    navigate('/')
  }

  function invitedChefs (): Choices | undefined {
    if (invitations.state !== 'hasValue') { return undefined } 
    return new Set(invitations.contents.sent_invitations.map(i => i.receiver.email))
  }

  return (
    <div className='view-container'>
      {userGroupLoadable.state === 'loading' && (<Spin />)}
      {userGroupLoadable.state === 'hasValue' && invitations.state === 'hasValue' && userGroupLoadable.contents && (
        <>
          <Navbar>
            <Button type="link" shape="round" size="small" onClick={goToMenu}>
              ← Recipes
            </Button>
          </Navbar>
          <Typography.Title level={1}>
            {/* TODO: make layout + h1 title more sane. Now needs a an empty spacer after the nav */}
          </Typography.Title>
          <Form
            layout="vertical"
          >
            <Form.Item
              label={<Typography.Title level={2} className="mgt-0">Currently Active Group</Typography.Title>}
            >
              <Select
                style={{ width: '100%' }}
                onChange={value => handleGroupChange(value)}
                options={groupItems()}
                value={selectedGroupIdLoadable.contents}
              />
            </Form.Item>
            <Card type="inner">
              {/* TODO: implement renaming groups somehwere, but an editable name input for all members as the first thing in the group card is just asking for trouble. */}
              {/* <Form.Item
                label={
                  <Typography.Title level={3} className="mgt-0">
                    Group Name
                  </Typography.Title>
                }
              >
                <Input
                  value={userGroup.contents.name}
                />
              </Form.Item> */}
              <Typography.Title level={3} className="mgt-0">
                Chefs
              </Typography.Title>
              <table>
                {userGroupLoadable.contents.owner && (
                  <tr>
                    <td>
                      <Avatar
                        style={{
                          // TODO: Maybe generate a color for each user so each avatar bubble is more unique than just initials.
                          backgroundColor: "green",
                          verticalAlign: "middle",
                        }}
                        size={48}
                        gap={6}>
                        {userGroupLoadable.contents.owner.user_profile.nick_name?.substring(0, 5)}
                      </Avatar>
                    </td>
                    <td style={{width: "90%"}}>
                      {userGroupLoadable.contents.owner.user_profile.nick_name}
                    </td>
                    <td>
                      {userGroupLoadable.contents.owner.email}
                    </td>
                  </tr>
                )}
                {userGroupLoadable.contents.members.map((user) => (
                  <tr>
                    <td>
                      <Avatar
                        style={{
                          // TODO: Maybe generate a color for each user so each avatar bubble is more unique than just initials.
                          backgroundColor: "green",
                          verticalAlign: "middle",
                        }}
                        size={48}
                        gap={6}>
                        {user.user_profile.nick_name?.substring(0, 5)}
                      </Avatar>
                    </td>
                    <td>
                      {user.user_profile.nick_name}
                    </td>
                    <td>
                      {user.email}
                    </td>
                  </tr>
                ))}
              </table>
              <AddMore
                value={invitedChefs()}
                label="Invite more chefs"
                button="Send invitation"
                onAdd={(value) => {
                  actions.create(groupID, value)
                }}
                onRemove={(value) => {
                  const index = invitations.contents.sent_invitations.findIndex(invitation => invitation.receiver.email === value)
                  actions.destroy(groupID, invitations.contents.sent_invitations[index].id)
                }}
              />
            </Card>
          </Form>
        </>
      )}
      <UserGroupForm />
    </div>
  );
}
import { useResetRecoilState, useSetRecoilState } from 'recoil'

import { sessionState } from '../atoms/session'
import { userProfileAtom, UserProfile } from '../atoms/user_profile_atom';
import { selectedGroupIdAtom } from '../atoms/user_group_atom';
import { LifeLongClient } from '../axios'

function useSessionActions() {

  const setSessionAtom = useSetRecoilState(sessionState)
  const setUserProfile = useSetRecoilState(userProfileAtom)
  const resetSelectedGroupIdAtom = useResetRecoilState(selectedGroupIdAtom)
  const client = LifeLongClient()

  async function getMagicLinkLogin(email: string) {
    await client.client().post(
      `/login/`,
      {
        email: email
      },
      {
        headers: {
          "Content-Type": "application/json"
        },
      });
  }

  async function getMagicLink(email: string, userProfile?: UserProfile) {
    await client.client().post(
      `/register/`,
      {
        email: email,
        profile: userProfile
      },
      {
        headers: {
          "Content-Type": "application/json"
        },
      });
  }

  async function verifyToken(token: string) {
    return client.client().post(
      `/sessions/`,
      {
        token: token
      },
      {
        headers: {
          "Content-Type": "application/json"
        },
      });
  }

  function logout() {
    setSessionAtom(null)
    setUserProfile(null)
    resetSelectedGroupIdAtom()
  }

  return { getMagicLink, logout, verifyToken, getMagicLinkLogin }
}

export { useSessionActions }

import {ReactNode} from "react"

export interface SquircleProps {
  radius: number //All inputs must have labels. (todo: link to an explanation why)
  color?: string
  background?: string
  children?: ReactNode
}

export function Squircle (props: SquircleProps) {
  
  const radius = props.radius
  const color = props.color || "inherit"
  const background = props.background || "white"

  return (
    <div
      className="squircle"
      style={{
        color: color,
        "--radius": `${radius}px`,
        "--background": background
      } as React.CSSProperties}
    >
      <div className="squircle-bg">
        <svg className="top-left" width="279px" height="279px" viewBox="0 0 279 279"><path stroke="none" fill={background} d="M279,8.52651283e-14 L279,279 L2.27373675e-13,279 C2.27373675e-13,152.5 1.745,88.005 44.87,44.88 C87.995,1.755 152.5,8.52651283e-14 279,8.52651283e-14 Z" id="squircle-bezier"></path></svg>
        <div className="top"></div>
        <svg className="top-right" width="279px" height="279px" viewBox="0 0 279 279"><path stroke="none" fill={background} d="M279,8.52651283e-14 L279,279 L2.27373675e-13,279 C2.27373675e-13,152.5 1.745,88.005 44.87,44.88 C87.995,1.755 152.5,8.52651283e-14 279,8.52651283e-14 Z" id="squircle-bezier"></path></svg>
        <div className="left"></div>
        <div className="center"></div>
        <div className="right"></div>
        <svg className="bottom-left" width="279px" height="279px" viewBox="0 0 279 279"><path stroke="none" fill={background} d="M279,8.52651283e-14 L279,279 L2.27373675e-13,279 C2.27373675e-13,152.5 1.745,88.005 44.87,44.88 C87.995,1.755 152.5,8.52651283e-14 279,8.52651283e-14 Z" id="squircle-bezier"></path></svg>
        <div className="bottom"></div>
        <svg className="bottom-right" width="279px" height="279px" viewBox="0 0 279 279"><path stroke="none" fill={background} d="M279,8.52651283e-14 L279,279 L2.27373675e-13,279 C2.27373675e-13,152.5 1.745,88.005 44.87,44.88 C87.995,1.755 152.5,8.52651283e-14 279,8.52651283e-14 Z" id="squircle-bezier"></path></svg>
      </div>
      <div className="squircle-content">
        {props.children}
      </div>
    </div>
  )
}
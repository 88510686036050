import { Typography } from "antd";

import NicknameForm from "../../components/profile/nickname_form";

export default function NicknameView() {
  return (
    <div className="text-align-center">
      <Typography.Title level={1}>
        Nickname
      </Typography.Title>
      <NicknameForm />
      <Typography.Paragraph>
        What do others in your group call you?
      </Typography.Paragraph>
    </div>
  );
}
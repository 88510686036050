import {Typography, Button, Card, Row, Col} from "antd"

import {ChooseOne} from "./ChooseOne"
import {MultipleChoice} from "./MultipleChoice"
import {StepsNav} from "./StepsNav.Ant"
import {YesNo} from "./YesNo"
import {AddMore} from "./AddMore.Ant"
import {ToggleButton} from "./ToggleButton.Ant"

/*
  TODO:
  Our choice components could actually be integrated to work with antd forms. The article says that most form frameworks work the same way, so that form components have a value and onChange.
  
  https://medium.com/swlh/use-custom-and-third-party-react-form-components-with-ant-design-and-typescript-2732e7849aee

  "We may want to integrate other third-party libraries that have their own way of dealing with the state of the component and notifying changes. Most should adhere to the same standard though whereby the value and onChange props are provided.""
*/
export default function FormsDemo () {

  return (
    <div className="view-container">
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <AddMore
            label="Add items to a list"
            button="Add item"
            onChange={(choices) => {
              console.log("AddMore onChange", choices)
            }}
            onAdd={(choice) => {
              console.log("AddMore onAdd", choice)
            }}
            onRemove={(choice) => {
              console.log("addmore onRemove", choice)
            }}
          />
        </Col>
        <Col span={24}>
          <MultipleChoice
            legend={
              <Typography.Title level={2}>
                Multiple choice (and optionally "add custom choices" field)
              </Typography.Title>
            }
            // addMoreLabel="Something else?"
            // addMoreButton="Add other option"
            onChange={(choices) => {
              console.log(choices);
            }}
            onAdd={(choice) => {
              console.log("AddMore onAdd", choice)
            }}
            onRemove={(choice) => {
              console.log("addmore onRemove", choice)
            }}
            options={[
              {
                label: "Bears",
                value: "brs"
              },
              {
                label: "Beavers",
                value: "bvrs"
              },
              {
                label: "Paltypuses",
                value: "pltpy"
              },
              {
                label: "Squirrels",
                value: "sqrl"
              },
              {
                label: "Dinosaurs",
                value: "dnsr"
              }
            ]}
            value={new Set(["bvrs"])}
            renderOptionLabel={(label, checked, i) => {
              return (
                // TODO: label gets wrapped in a button, this needs to be a visualisation of a toggle, not an actual button.
                <ToggleButton pressed={checked}>
                  {label}
                </ToggleButton>
              )
            }}
          >
            <Typography.Paragraph>
              Optional extra content related to the question goes here.
            </Typography.Paragraph>
          </MultipleChoice>
          <AddMore
            label="Something else?" //{addMoreLabel}
            button="Add item" //{addMoreButton}
          />
        </Col>
        <Col span={24}>
          <ChooseOne
            legend={
              <Typography.Title level={2}>
                Choose one (Usually two? But can be more!)
              </Typography.Title>
            }
            onChange={(choice) => {
              //DO something with the choice
              console.log(choice);
            }}
            options={[
              {
                //Label doesn't have to be only text, can be an object or whatever, because option rendering is not defined inside the component.
                label: "🤨 Sus",
                value: 0
              },
              {
                label: "⭐️ Whoop!",
                value: 1
              },
              {
                label: "🤯 Ohhh!",
                value: 55
              },
            ]}
            renderOptionLabel={(label, checked, i) => {
              return (
                <ToggleButton pressed={checked}>
                  {label}
                </ToggleButton>
              )
            }}
          >
            <Typography.Paragraph>
              Optional extra content related to the question goes here.
            </Typography.Paragraph>
          </ChooseOne>
        </Col>
        <Col span={24}>
          <ChooseOne
            legend={
              <Typography.Title level={2}>
                Which Food Do You Like More?
              </Typography.Title>
            }
            onChange={() => {}}
            options={[
              {
                label: {
                  emoji: "🌵",
                  text: "Cactus Cookies"
                },
                value: "cactusCookies"
              },
              {
                label: {
                  emoji: "🐍",
                  text: "Snake Turd"
                },
                value: "snakeTurd"
              },
            ]}
            renderOptionLabel={(label: any, checked, i) => {
              return (
                <Card type="inner" className="recipe-summary-card">
                  <br />
                  <Typography.Title level={3} className="recipe-summary-title">
                    <span className="recipe-summary-emoji">
                      {label.emoji}
                    </span>
                    {label.text}
                  </Typography.Title>
                </Card>
              )
            }}
          >
            <Typography.Paragraph>
              This is an example of any custom rendering of options.
            </Typography.Paragraph>
          </ChooseOne>
        </Col>
        <Col span={24}>
          <YesNo
            legend={
              <Typography.Title level={2}>
                Yes/No/Good/Bad/Positive/Negative question, like: "Is this bad?"
              </Typography.Title>
            }
            onClick={(choice) => {
              console.log(choice)
            }}
            yes={{
              label: <Button type="primary" shape="round" size="large">⭐️ Yes</Button>,
              value: true
            }}
            no={{
              label: <Button type="primary" shape="round" size="large">Nope</Button>,
              value: false
            }}
          >
            <Card>
              This is context for the yes/no question. Could be a card, image, recipe, whatever.
            </Card>
          </YesNo>
        </Col>
        <Col span={24}>
          <StepsNav />
        </Col>
      </Row>
    </div>
  )

}
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ConfigProvider, Layout, message } from 'antd'

import { useRecoilState, useRecoilValue } from "recoil";
import { sessionState } from './atoms/session'

import theme from './theme.json'
// Logged out routes
import SignupView from './routes/signup_view/signup_view';
import LandingView from './routes/landing_view/landing_view';
import VerifyTokenView from './routes/verify_token_view/verify_token_view';

// Logged in routes
import GroupMembersView from './routes/group_view/group_view';
import GroupRecipesView from './routes/group_recipes_view/group_recipes_view';
import InviteView from './routes/group_view/invite_view';
import RecipeView from './routes/group_recipes_view/recipe_view';
import RecipeDirectionView from './routes/group_recipes_view/recipe_direction_view';
import RecipeDoneView from './routes/group_recipes_view/recipe_done_view';
import RecipeReviewCookingView from './routes/group_recipes_view/recipe_review_cooking_view';
import RecipeReviewCookingFeedbackView from './routes/group_recipes_view/recipe_review_cooking_feedback_view';
import RecipeReviewEatingView from './routes/group_recipes_view/recipe_review_eating_view';
import RecipeReviewEatingFeedbackView from './routes/group_recipes_view/recipe_review_eating_feedback_view';
import RecipeReviewSkillView from './routes/group_recipes_view/recipe_review_skill_view';
import RecipeReviewThanksView from './routes/group_recipes_view/recipe_review_thanks_view';
import ShoppingListView from './routes/group_recipes_view/shopping_list_view';
import UserProfileView from './routes/user_profile_view/user_profile_view';

import ChoiceComponentDemo from './components/choiceui';
import SquircleIndex from './components/presentation/SquircleIndex';
import QuestsIndex from './components/presentation/QuestsIndex';

// Common routes
import NotFoundView from './routes/not_found_view';
import ProtectedRoute from './routes/protected_route/protected_route';

import VueCableProvider from './providers/cable_provider';

const App = () => {
  const { Content } = Layout

  const session = useRecoilValue(sessionState)

  return (
    <VueCableProvider>
      <ConfigProvider theme={theme}>
        <Layout className="full-height">
          <Content>
            <Routes>
              <Route path="/demo/quests" element={<QuestsIndex />} />
              <Route path="/demo/squircle" element={<SquircleIndex />} />
              <Route path="/demo/choiceui" element={<ChoiceComponentDemo />} />
              {session && (
                <>
                  <Route path="/" element={<ProtectedRoute><GroupRecipesView /></ProtectedRoute>} />
                  <Route path="/group/:id" element={<ProtectedRoute><GroupMembersView /></ProtectedRoute>} />
                  <Route path="/group/:id/shopping-list" element={<ProtectedRoute><ShoppingListView /></ProtectedRoute>} />
                  <Route path="/invite" element={<ProtectedRoute><InviteView /></ProtectedRoute>} />
                  <Route path="/profile" element={<ProtectedRoute><UserProfileView /></ProtectedRoute>} />
                  <Route path="/recipe/:id" element={<ProtectedRoute><RecipeView /></ProtectedRoute>} />
                  <Route path="/recipe/:id/step/:step" element={<ProtectedRoute><RecipeDirectionView /></ProtectedRoute>} />
                  <Route path="/recipe/:id/done" element={<ProtectedRoute><RecipeDoneView /></ProtectedRoute>} />
                  <Route path="/recipe/:id/review/cooking" element={<ProtectedRoute><RecipeReviewCookingView /></ProtectedRoute>} />
                  <Route path="/recipe/:id/review/cooking_feedback" element={<ProtectedRoute><RecipeReviewCookingFeedbackView /></ProtectedRoute>} />
                  <Route path="/recipe/:id/review/eating" element={<ProtectedRoute><RecipeReviewEatingView /></ProtectedRoute>} />
                  <Route path="/recipe/:id/review/eating_feedback" element={<ProtectedRoute><RecipeReviewEatingFeedbackView /></ProtectedRoute>} />
                  <Route path="/recipe/:id/review/skill" element={<ProtectedRoute><RecipeReviewSkillView /></ProtectedRoute>} />
                  <Route path="/recipe/review/thanks" element={<ProtectedRoute><RecipeReviewThanksView /></ProtectedRoute>} />                  
                </>
              )}
              {!session && (
                <>
                  <Route path="/" element={<LandingView />} />                  
                </>
              )}
              <Route path="/validate/:token" element={<VerifyTokenView />} />
              <Route path="/signup" element={<SignupView />} />
              <Route path='*' element={<NotFoundView />} />
            </Routes>
          </Content>
        </Layout >
      </ConfigProvider>
    </VueCableProvider>
  )
}

export default App;

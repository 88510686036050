import { ReactNode } from "react"

import { Button, ButtonProps } from "antd"
import { CheckOutlined } from "@ant-design/icons"

interface ToggleButtonProps {
  pressed: boolean
  children?: ReactNode
  icon?: ReactNode | boolean
}

type Props = ButtonProps & ToggleButtonProps

//TODO: Make this component into something like ToggleLabel that's just a default visualisation for a toggleable option label. Not a button at all, since everywhere this is now used this is wrapped in a button and that leads to a button inside a button.
export function ToggleButton (props: Props) {

  const pressed = props.pressed

  //Set icon to `false` if you don't want to show it at all
  const showIcon = (props.icon !== false) && pressed
  const icon = props.icon || <CheckOutlined />

  return (
    <Button
      {...props}
      className={`ant-btn-checkable width-full ${props.className}`}
      type={pressed ? "primary" : "default"}
      aria-pressed={pressed ? "true" : "false"}
      htmlType="button"
    >
      {showIcon && (
        icon
      )}
      <span>
        {props.children}
      </span>
    </Button>
  )
}
import { Typography } from "antd";

import BirthdayForm from "../../components/profile/birthday_form";

export default function BirthdayView() {
  return (
    <>
      <div className="text-align-center">
        <Typography.Title level={1}>
          Birthday
        </Typography.Title>
        <div className="width-full" style={{display: "inline-block", maxWidth: "400px"}}>
          <BirthdayForm />
        </div>
        <Typography.Paragraph>
          We will give you cooking tasks that fit your age.
        </Typography.Paragraph>
      </div>
    </>
  );
}
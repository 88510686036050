import {ReactNode} from "react"

import {Tag, Typography, Card, Avatar} from "antd";

interface RecipeThumbProps {
  emoji: ReactNode
  title: string
  tags?: string[]
  avatars?: ReactNode
  description?: ReactNode
  badge?: ReactNode
  overlay?: ReactNode
}

// TODO: Do some styling for this, css grid layout or something
export function RecipeThumb (props: RecipeThumbProps) {
  return (
    <div className="recipe-thumb">
      <div className="recipe-thumb-emoji">
        {props.emoji}
      </div>
      <Typography.Title level={3} className="recipe-thumb-title">
        {props.title}
      </Typography.Title>
      {props.avatars && (
        <div className="recipe-thumb-avatars">
          {props.avatars}
        </div>
      )}
      {props.tags && props.tags.length > 0 && (
        <div className="recipe-thumb-tags">
          {props.tags?.map((tag) => {
              return (tag && <Tag>{tag}</Tag>)
            } 
          )}
        </div>
      )}
      {props.badge && (
        <div className="recipe-thumb-badge">
          {props.badge}
        </div>
      )}
      {props.description && (
        <div className="recipe-thumb-description">
          {props.description}
        </div>
      )}
      {props.overlay && (
        <div className="recipe-thumb-overlay">
          {props.overlay}
        </div>
      )}
    </div>
  )
}
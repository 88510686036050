import { useContext, useEffect, useState } from "react"
import { useRecoilValueLoadable, useRecoilStateLoadable, useRecoilRefresher_UNSTABLE } from "recoil"
import { useNavigate } from "react-router-dom"
import dayjs from 'dayjs'

import { Select, Modal, Avatar, Tag, Spin, Button, Card, Col, Popconfirm, Row, Typography } from "antd"
import { ReloadOutlined, UsergroupAddOutlined, UserOutlined } from "@ant-design/icons"

import { useSessionActions } from "../../actions/session_actions"
import { useUserProfileActions } from "../../actions/user_profile_actions"

import { GroupRecipe, RecipeStatus, UserGroupRecipesQueryParams, filteredRecipesSelector } from "../../atoms/recipes_atom"
import { UserProfile } from "../../atoms/user_profile_atom"
import { selectedGroupIdState, userGroupsQuery, UserGroup, userGroupQuery } from "../../atoms/user_group_atom"
import { invitationsAtom } from "../../atoms/invitations_atom"

import Navbar from "../../components/Navbar"
import { RecipeThumb } from "../../components/recipe/RecipeThumb"
import Members from "../../components/group/members"
import { YesNo } from "../../components/choiceui/YesNo"
import { ChooseOne } from "../../components/choiceui/ChooseOne"
import AvatarRound from "../../components/group/avatar_round"
import { invitationsActions } from "../../actions/invitations_actions"
import { VueCableContext } from "../../providers/cable_provider"
import { userGroupRecipesActions } from "../../actions/user_group_recipes_actions"

const GroupRecipesView = () => {

  const userGroups = useRecoilValueLoadable(userGroupsQuery)
  const [selectedGroupIdLoadable, setSelectedGroupId] = useRecoilStateLoadable(selectedGroupIdState);
  const userGroup = useRecoilValueLoadable(userGroupQuery(selectedGroupIdLoadable.state === 'hasValue' ? selectedGroupIdLoadable.contents : null))
  const invitations = useRecoilValueLoadable(invitationsAtom)
  const resetInvitations = useRecoilRefresher_UNSTABLE(invitationsAtom)
  const inviActions = invitationsActions()
  const cable = useContext(VueCableContext)?.cable || null;

  const [userGroupRecipeParams, setUserGroupRecipeParams] = useState<UserGroupRecipesQueryParams>({ groupId: null })

  const recipes = useRecoilValueLoadable(filteredRecipesSelector(userGroupRecipeParams))
  const resetRecipe = useRecoilRefresher_UNSTABLE(filteredRecipesSelector(userGroupRecipeParams))

  const profilesChanged = true //TODO

  const sessionActions = useSessionActions()
  const userProfileActions = useUserProfileActions()

  const navigate = useNavigate()

  const [recipesContents, setRecipesContents] = useState<GroupRecipe[]>([]);

  useEffect(() => {
    if (recipes.state === 'hasValue') {
      setRecipesContents(recipes.contents);
    }
  }, [recipes]);

  function groupItems() {
    if (userGroups.state !== 'hasValue') { return [] }
    const allGroups = [...userGroups.contents.owned_user_groups, ...userGroups.contents.user_groups]
    return allGroups.map((group: UserGroup) => {
      return {
        value: group.id,
        label: group.name
      }
    })
  }

  function logout() {
    sessionActions.logout()
  }

  //TODO: these navigation actions should be in their own file or something. We can use just paths for navigations, but they really should be abstracted into something else so this function would at the very least be like navigate(PATHS.profile), but the functions themselves could also be in a file that is somehow codependent with our routes.
  function goToProfile() {
    navigate("/profile")
  }

  function goToShoppingList() {
    navigate(`/group/${selectedGroupIdLoadable.contents}/shopping-list/`)
  }

  function goToGroup() {
    navigate(`/group/${selectedGroupIdLoadable.contents}`)
  }

  function goToRecipe(recipe: GroupRecipe) {
    if (recipe.status === 'finished' && recipe.chef_review) {
      navigate(`/recipe/${recipe.id}/review/cooking`)
    }
    else if (recipe.status === 'finished' && recipe.eater_review) {
      navigate(`/recipe/${recipe.id}/review/eating`)
    }
    else {
      navigate(`/recipe/${recipe.id}`)
    }
  }

  //TODO: Now that we fixed sign up & onboarding, this effect is obsolete, right? In any case rerouting based on session/user status should be done at ProtectedRoute level, right?
  useEffect(() => {
    userProfileActions.fetchUserProfile().then((response: UserProfile) => {
      if (response?.birthday === undefined || response?.nick_name === undefined) {
        navigate('/signup')
      }
    })
  }, [])

  useEffect(() => {
    if (cable && selectedGroupIdLoadable.state === 'hasValue' && selectedGroupIdLoadable.contents) {
      const channel = cable.subscribeTo('UserGroupChannel', { user_group_id: selectedGroupIdLoadable.contents })
      channel.on('message', msg => {
        resetRecipe()
      })
      return () => {
        channel.disconnect()
      }
    }
  }, [cable, selectedGroupIdLoadable.contents, resetRecipe])

  useEffect(() => {
    if (selectedGroupIdLoadable.state === 'hasValue') {
      setUserGroupRecipeParams({ ...userGroupRecipeParams, groupId: String(selectedGroupIdLoadable.contents) })
    }
  }, [selectedGroupIdLoadable])

  //TODO: handleGroupChange functionality is copy & pasted to group page also. Unify this somehow.
  function handleGroupChange(newGroupId: number) {
    setSelectedGroupId(newGroupId)
  }

  function recipeIsAwaitingReview(recipe: GroupRecipe) {
    if (recipe.status === RecipeStatus.finished &&
      ((recipe.chef_review?.id && recipe.chef_review.completed === false) ||
        (recipe.eater_review?.id && recipe.eater_review.published_at === null))
    ) {
      return true
    }
    return false
  }

  function renderRecipe(recipe: GroupRecipe) {
    return (
      <div key={recipe.id}>
        <Typography.Title level={4}>
          {dayjs(Date.parse(recipe.cooking_date)).format('dddd')}
        </Typography.Title>
        <div className="recipe-block">
          <button className="transparent" onClick={() => { goToRecipe(recipe) }}>
            <RecipeThumb
              emoji={recipe.data?.emoji || <Spin size="large" />}
              title={
                recipe.data?.recipe
                || "Choosing recipe"
              }
              /*
                TODO: pick and cycle something more fun than "Choosing recipe"
                Organising utentsils
                Closing the fridge
                Checking pantry
                Opening drawer
                Picking berries
              */
              tags={recipeIsAwaitingReview(recipe) ? [] : [
                recipe.data?.servings + " servings",
                recipe.data?.preparation_time
              ]}
              description={recipeIsAwaitingReview(recipe) && (
                <Typography.Title level={4} className="fade">
                  Done! Review this recipe.
                </Typography.Title>
              )}
              badge={recipeIsAwaitingReview(recipe) && (
                <>✅</>
              )}
              avatars={
                <Avatar.Group>
                  <AvatarRound
                    nickname={recipe.main_seat.user_profile.nick_name || ""}
                    initials={recipe.main_seat.user_profile.nick_name?.substring(0, 2) || ""}
                    email={recipe.main_seat.email} />
                  {recipe.fill_in_seat && (
                    <AvatarRound
                      nickname={recipe.fill_in_seat.user_profile.nick_name || ""}
                      initials={recipe.fill_in_seat.user_profile.nick_name?.substring(0, 2) || ""}
                      email={recipe.fill_in_seat.email} />
                  )}
                </Avatar.Group>
              }
            />
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="view-container">
      {selectedGroupIdLoadable.state === 'loading' && (
        <Row className="text-align-center" gutter={[8, 8]}>
          <Col span={24}>
            <Typography.Title>&nbsp;</Typography.Title>
          </Col>
          <Col span={24}>
            <Spin />
          </Col>
          <Col span={24}>
            Just a second, sorting all the forks.
          </Col>
        </Row>
      )}
      {selectedGroupIdLoadable.state === 'hasValue' && (
        <>
          <Navbar>
            <div className="navbar-groups">
              <Select
                style={{ width: '100%' }}
                onChange={value => handleGroupChange(value)}
                options={groupItems()}
                size="small"
                bordered={false}
                value={selectedGroupIdLoadable.contents}
              />
              <Members />
            </div>
            <div className="flex-grow-1"></div>
            <Button shape="round" size="small" onClick={goToGroup}>
              <UsergroupAddOutlined />
              Group
            </Button>
            <Button shape="round" size="small" onClick={goToProfile}>
              <UserOutlined />
              Profile
            </Button>
            <Button shape="round" size="small" htmlType="submit" onClick={logout}>
              Logout
            </Button>
          </Navbar>
          <Typography.Title level={1} className="view-title">
            <div className="flex align-items-center justify-content-">
              Recipes
              <div className="flex-grow-1"></div>
              <Popconfirm
                onConfirm={() => {
                  if (selectedGroupIdLoadable.state === 'hasValue') {
                    userGroupRecipesActions().generate(String(selectedGroupIdLoadable.contents!))
                      .then(() => { resetRecipe() })
                  }
                }}
                title="Generate recipes?">
                {/*
                  TODO: Get rid of this button when we can. Also if group size is 1 and you click this, the back end returns a 422 Unprocessable entity.
                */}
                <Button shape="round" size="small">
                  <ReloadOutlined />
                  Generate Recipes
                </Button>
              </Popconfirm>
            </div>
          </Typography.Title>
          {/* NOTE: invitation confirmations will be shown one by one if there are many. An edge case for sure, but these dialogs should be cycled one after the other nicely. */}
          {invitations.state === 'hasValue' && invitations.contents.length > 0 && (
            <Card type="inner" style={{ marginBottom: '16px' }}>
              <YesNo
                onClick={(choice) => {
                  if (choice) {
                    inviActions.accept(invitations.contents[0].user_group.id, invitations.contents[0].id)
                      .then(() => resetInvitations())
                  } else {
                    inviActions.decline(invitations.contents[0].user_group.id, invitations.contents[0].id)
                      .then(() => resetInvitations())
                  }
                }}
                legend={
                  <Typography.Title level={4} className="mgt-0 text-align-center">
                    {invitations.contents[0].sender.user_profile?.nick_name} invited you to join their group!
                  </Typography.Title>
                }
                no={{
                  label: <Button type="ghost" size="small" shape="round">Nah, buzz off</Button>,
                  value: false
                }}
                yes={{
                  label: <Button size="small" shape="round">Let's go!</Button>,
                  value: true
                }}
              />
            </Card>
          )}
          {!profilesChanged && (
            <Card type="inner" style={{ marginBottom: '16px' }}>
              <YesNo
                onClick={(choice) => {
                  //TODO: handle choice here
                  console.log(choice)
                }}
                legend={
                  <Typography.Title level={4} className="mgt-0 text-align-center">
                    {/* TODO: [NAME_OR_NAMES_HERE] changed their [CATEGORY_OR_CATEGORIES_THAT_CHANGED]. Do you want to get new recipes? */}
                    Ms. Salad and Mr. Pizza changed their allergies and dietary restrictions. Do you want to get new recipes?
                  </Typography.Title>
                }
                yes={{
                  label: <Button type="ghost" size="small" shape="round">Keep Recipes</Button>,
                  value: false
                }}
                no={{
                  label: <Button size="small" shape="round">Get New Recipes</Button>,
                  value: true
                }}
              />
            </Card>
          )}
          {userGroup.state === 'hasValue' && userGroup.contents?.members.length === 0 && (
            <Card type="inner" className="text-align-center">
              <YesNo
                onClick={(choice) => {
                  //TODO: handle choice here
                  console.log(choice)
                }}
                legend={
                  <Typography.Title level={4} className="mgt-0">
                    Invite more chefs to your kitchen to cook together
                  </Typography.Title>
                }
                yes={{
                  label: <Button size="small" shape="round" type="primary" onClick={goToGroup}>Invite Chefs</Button>,
                  value: false
                }}
                no={{
                  label: <Button size="small" shape="round">Nah, later</Button>,
                  value: true
                }}
              >
                <Typography.Paragraph>
                  You can send invites later from the Group page.
                </Typography.Paragraph>
              </YesNo>
            </Card>
          )}
          <Button shape="round" onClick={goToShoppingList}>
            Shopping List
          </Button>
          <ChooseOne
            legend={
              <Typography.Title level={2}>
                Which dish would you prefer?
              </Typography.Title>
            }
            onChange={(choice) => {
              console.log(choice);
            }}
            options={[
              {
                label: {
                  emoji: "🌵",
                  title: "Cacturs Spike Soup",
                  description: <Typography.Paragraph>Deliciously supple oven softened bits of cactus.</Typography.Paragraph>
                },
                value: 0
              },
              {
                label: {
                  emoji: "👻",
                  title: "Spooky Pumpkins",
                  description: <Typography.Paragraph>The scariest food you will ever eat!</Typography.Paragraph>
                },
                value: 1
              },
            ]}
            renderOptionLabel={(label: any, checked, i) => {
              return (
                <div>
                  <RecipeThumb
                    emoji={label.emoji}
                    title={label.title}
                    description={label.description}
                  />
                </div>
              )
            }}
          >
          </ChooseOne>
          {recipesContents.map(renderRecipe)}
        </>
      )}
    </div>
  )
}

export default GroupRecipesView

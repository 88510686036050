import { LifeLongClient } from '../axios'

export  const userGroupRecipesActions = () => {
  const client = LifeLongClient()
  
  async function index(groupId: string) {    
    const { data } = await client.client().get(`/recipes`, { params: { user_group_id: groupId }})
    return data
  }

  async function show(recipeId: string) {
    const { data } = await client.client().get(`/recipes/${recipeId}`)
    return data
  }

  async function generate(groupId: string) {
    const { data } = await client.client().post(`/recipes/generate`, { user_group_id: groupId })
    return data
  }

  return {
    index,
    show,
    generate
  }
}
import { Button, Avatar, Col, Form, Input, Typography, Row, List } from "antd"
import { useRecoilValueLoadable, useRecoilRefresher_UNSTABLE } from "recoil"

import { userGroupsQuery } from "../../atoms/user_group_atom";
import { userGroupActions } from "../../actions/user_group_actions"
import { userDataQuery } from "../../atoms/user_atom";
import AvatarRound from "../../components/group/avatar_round"

export default function UserGroupForm() {
  const userGroups = useRecoilValueLoadable(userGroupsQuery)
  const resetUserGroups = useRecoilRefresher_UNSTABLE(userGroupsQuery)
  const userData = useRecoilValueLoadable(userDataQuery)

  const actions = userGroupActions()
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    actions.createUserGroup(values.group)
      .then(() => {
        resetUserGroups()
        form.setFieldValue("group", "")
      })
  }

  const onDeleteGroup = (id: number) => {
    actions.destroy(id)
      .then(() => {
        resetUserGroups()
      })
  }

  const onLeaveGroup = (group_id: number) => {
    if (userData.state === 'hasValue') {
      actions.removeUser(group_id, userData.contents.id)
      .then(() => {
        resetUserGroups()
      })
    }    
  }

  return (
    <>
      {/* {userGroups.state === 'hasValue' && ( */}
      <>
        <Typography.Title level={2}>
          Groups I've made
        </Typography.Title>
        {/* TODO: make component to render a group in a list. Now this same thing is repeated twice. */}
        <List
          loading={userGroups.state === 'loading'}
          itemLayout="horizontal"
          dataSource={userGroups.state === 'hasValue' ? userGroups.contents.owned_user_groups : undefined}
          renderItem={(group) => (
            <List.Item
              actions={[<Button onClick={() => onDeleteGroup(group.id)}>Delete</Button>]}>
              <List.Item.Meta
                title={group.name}
                // TODO The owner is a member of a group too. We should unify how we handle owner only, members minus owner and all members situations.
                description={`${group.members.length+1} members`}
              />
              {/* TODO: Use <Members /> component to render group members. Give it a parameter of group so it doesn't just load the current groups members. */}
              <Avatar.Group>
                {group && (
                  <AvatarRound key={group.owner.email}
                    nickname={group.owner.user_profile?.nick_name || group.owner.email}
                    initials={group.owner.user_profile?.nick_name?.substring(0, 2) || group.owner.email.substring(0, 2)}
                    email={group.owner.email} />
                )}
                {group?.members.map((member) => (
                  <AvatarRound
                    key={member.email}
                    nickname={member.user_profile.nick_name || member.email}
                    initials={member.user_profile.nick_name?.substring(0, 2) || member.email.substring(0, 2)}
                    email={member.email} />
                ))}
              </Avatar.Group>
            </List.Item>
          )}
        />
        {userGroups.state === 'hasValue' && userGroups.contents.user_groups.length > 0 && (
          <>
            <Typography.Title level={2}>
              Groups I've joined
            </Typography.Title>
            <List
              itemLayout="horizontal"
              dataSource={userGroups.state === 'hasValue' ? userGroups.contents.user_groups : undefined}
              renderItem={(group) => (
                <List.Item actions={[<Button onClick={() => onLeaveGroup(group.id, )}>Leave</Button>]}>
                  <List.Item.Meta
                    title={group.name}
                    description={`Owner: ${group.owner.user_profile.nick_name}`}
                  />
                  <Avatar.Group>
                    {group && (
                      <AvatarRound key={group.owner.email}
                        nickname={group.owner.user_profile?.nick_name || group.owner.email}
                        initials={group.owner.user_profile?.nick_name?.substring(0, 2) || group.owner.email.substring(0, 2)}
                        email={group.owner.email} />
                    )}
                    {group?.members.map((member) => (
                      <AvatarRound
                        key={member.email}
                        nickname={member.user_profile.nick_name || member.email}
                        initials={member.user_profile.nick_name?.substring(0, 2) || member.email.substring(0, 2)}
                        email={member.email} />
                    ))}
                  </Avatar.Group>
                </List.Item>
              )}
            /></>
        )}

        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <Row gutter={[8, 8]} align="bottom">
            <Col flex="1 0 auto">
              <Form.Item
                name="group"
                label={
                  <Typography.Title level={2}>
                    Create a New Group
                  </Typography.Title>
                }
                rules={[
                  {
                    required: true,
                    message: 'Please write an group name first.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col flex="0 0 min-content">
              <Form.Item>
                <Button htmlType="submit">Create group</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
      {/* )} */}
    </>
  )
}

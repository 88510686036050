import { cloneElement, useState, ReactNode, ReactElement } from "react"

import type {Option, Options, Choice, Choices} from './types.js'

export interface YesNoProps {
  legend: ReactNode //The question
  children?: ReactNode //Any additional content that is needed for the question
  yes: Option
  no: Option
  onClick: (choice: Choice) => void
}

//yes and no Option labels need to be elements, can't be just strings. Could make it so if you pass in a string, we render only a basic htnml <button> element with that label? That way this component would always work, but also not have a ui framework dependency.
//TODO: makeButton should be shared between all choice ui components
function makeButton (element: ReactElement, callback: Function) {
  return cloneElement(element, {
    tabIndex: 0,
    role: "button",
    onClick: callback
  })
}

export function YesNo (props: YesNoProps) {

  const onYes = () => props.onClick(props.yes.value)
  const onNo = () => props.onClick(props.no.value)
  const yesButton = makeButton(props.yes.label, onYes)
  const noButton = makeButton(props.no.label, onNo)

  return (
    <>
      <fieldset className="vuo-question vuo-yesno">
        <legend>
          {props.legend}
        </legend>
        {props.children}
        <div className="vuo-actions">
          {noButton}
          {yesButton}
        </div>
      </fieldset>
    </>
  )
}
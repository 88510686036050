import { useRef } from "react";
import { Form, Row, Input, Typography } from "antd";
import { useRecoilState } from "recoil";

import { userProfileAtom } from "../../atoms/user_profile_atom";
import { useUserProfileActions } from "../../actions/user_profile_actions";

type NicknameFormProps = {
  // TODO: implement saving to db when this is called from profile view
  shouldUpdateDB?: boolean
}

export default function NicknameForm(props: NicknameFormProps) {
  const userProfileActions = useUserProfileActions()
  const timeoutHandler = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserProfile({ ...userProfile, nick_name: event.target.value })
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current)
    }
    timeoutHandler.current = setTimeout(() => {
      if (props.shouldUpdateDB) {
        userProfileActions.saveUserProfile()
      } else {
        userProfileActions.changeNickname(event.target.value)
      }
    }, 1000);    
  }

  return (
    <Form
      name="nickname"
      layout="vertical"
      requiredMark={false}  
      initialValues={{ nickname: userProfile?.nick_name }}
    >
      {/*
        TODO: validate nickname with onBlur event and when pressing Next button.
        https://ant.design/components/form#components-form-demo-without-form-create
      */}
      <Form.Item
        name="nickname"
        label="Nickname"
        rules={[
          {
            required: true,
            message: 'Please add your nickname.',
          },
        ]}
      >
        <Input
          onChange={onChange}
          style={{ maxWidth: '300px' }}
          className="width-full"
        />
      </Form.Item>
    </Form>
  );
}
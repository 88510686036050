import {Typography, Button, Card, Row, Col} from "antd"

import {Squircle} from "./Squircle"

//Demo page for quest tags. Can be accessd in the browser at `/demo/quests`
export default function SquircleIndexView () {
  return (
    <>
      <div className="flex align-items-start flex-wrap" style={{padding: "32px", gap: "16px"}}>
        <Squircle radius={32} background="rgba(255,255,255,.9)">
          <Typography.Title level={4} className="text-align-center mg-0">
            I am content in a squircle.
          </Typography.Title>
        </Squircle>
        <Squircle radius={16} background="rgba(255,255,255,.9)">
          <div className="text-align-center">
            Small squircle
          </div>
        </Squircle>
        <Squircle radius={128} background="rgba(255,255,255,.9)">
          <Typography.Title level={1} className="text-align-center mg-0">
            A am huge 🤯
          </Typography.Title>
        </Squircle>
      </div>
    </>
  )
}
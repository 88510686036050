import { useEffect, useState } from 'react'
import { Form, Row, Col, Select, Typography } from "antd";
import dayjs, { locale } from 'dayjs'
import { useRecoilCallback, useRecoilState } from 'recoil';
import localeData from 'dayjs/plugin/localeData'

import { userProfileAtom } from '../../atoms/user_profile_atom';
import { useUserProfileActions } from '../../actions/user_profile_actions';

type BirthdayFormProps = {
  shouldUpdateDB?: boolean
}

export default function BirthdayForm(props:  BirthdayFormProps) {

  const userProfileActions = useUserProfileActions()
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom)

  const [day, setDay] = useState<string>(userProfileActions.getBirthDayDateValue("day"))
  const [month, setMonth] = useState<string>(userProfileActions.getBirthDayDateValue("month"))
  const [year, setYear] = useState<string>(userProfileActions.getBirthDayDateValue("year"))

  dayjs.extend(localeData)

  const days = Array.from({ length: 31 }, (_, index) => ({ value: index + 1, title: index + 1 }))
  const months = dayjs.months().map((m, index) => ({ value: index + 1, label: m }))
  const years = Array.from({ length: 60 }, (value: number, index) => ({ value: (dayjs().year() - 5) - index }))  

  const onDayChange = (value: string) => {
    setDay(value)
    if (value && month && year && props.shouldUpdateDB) {
      userProfileActions.changeBirthday(value, month, year, true)
    } else {
      userProfileActions.changeBirthday(value, month, year)
    }
  }

  const onMonthChange = (value: string) => {
    setMonth(value)
    if (value && day && year && props.shouldUpdateDB) {
      userProfileActions.changeBirthday(day, value, year, true)
    } else {
      userProfileActions.changeBirthday(day, value, year)
    }
  }

  const onYearChange = (value: string) => {
    setYear(value)
    if (value && day && month && props.shouldUpdateDB) {
      userProfileActions.changeBirthday(day, month, value, true)
    } else {
      userProfileActions.changeBirthday(day, month, value)
    }   
  }

  const initialValues = () => {
    return {
      "day": day,
      "month": month,
      "year": year
    }
  }  

  return (
    <Form
      name="birthday"
      initialValues={initialValues()}
      layout="vertical"
      requiredMark={false}
    >
      {/* TODO: validate fields with onBlur event and when pressing Next button.
        https://ant.design/components/form#components-form-demo-without-form-create */}
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <Form.Item
            name="day"
            label="Day"
            rules={[
              {
                required: true,
                message: 'Select day.',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              onChange={value => onDayChange(value)}
              options={[...days]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="month"
            label="Month"
            rules={[
              {
                required: true,
                message: 'Select month.',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              onChange={value => onMonthChange(value)}
              options={[...months]}
            />
            </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="year"
            label="Year"
            rules={[
              {
                required: true,
                message: 'Select year.',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              onChange={value => onYearChange(value)}
              options={[...years]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
import { useRecoilState, useSetRecoilState } from 'recoil'
import { LifeLongClient } from '../axios'
import {
  recipesAtom
} from "../atoms/recipes_atom";
import { chefRecipeReviewAtom } from '../atoms/chef_recipe_review';
import { ChefRecipeReviewParameters } from './recipes_review_actions';

function useChefRecipeReviewActions() {

  const client = LifeLongClient()
  const [recipes, setRecipes] = useRecoilState(recipesAtom)
  const setChefRecipeReview = useSetRecoilState(chefRecipeReviewAtom)

  async function fetchChefRecipeReview(recipeId: string) {
    const { data } = await client.client().get(`/recipes/${recipeId}/chef_recipe_review`)
    setChefRecipeReview(data)
  }

  async function updateChefRecipeReview(params: ChefRecipeReviewParameters) {
    const { data } = await client.client().patch(`/recipes/${params.recipe_id}/chef_recipe_review`, params)
    const newRecipes = recipes.map(recipe => {
      if (recipe.id === params.recipe_id) {
        const newRecipe = { ... recipe }
        newRecipe.chef_review = data
        return newRecipe
      }
      return recipe
    })
    setRecipes(newRecipes)
  }

  async function publishChefRecipeReview(recipeId: string) {
    const { data } = await client.client().patch(`/recipes/${recipeId}/chef_recipe_review/publish`)
    const newRecipes = recipes.map(recipe => {
      if (recipe.id === recipeId) {
        const newRecipe = { ... recipe }
        newRecipe.chef_review = data
        return newRecipe
      }
      return recipe
    })
    setRecipes(newRecipes)
  }

  return {
    fetchChefRecipeReview,
    updateChefRecipeReview,
    publishChefRecipeReview
  }
}

export { useChefRecipeReviewActions }
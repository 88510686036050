import { Typography, Card } from "antd";
import { GroupRecipe } from "../../atoms/recipes_atom";

interface NutritionLabelCardProps {
  recipe: GroupRecipe
}

export default function NutritionLabelCard({ recipe }: NutritionLabelCardProps) {

  return (
    <Card type="inner" className="recipe-nutrition-label-card">
      <Typography.Title level={4} className="mgt-0">
        Nutrition
      </Typography.Title>
      <Typography.Paragraph>
        <table className="vertical-align-top nowrap">
          <tbody>
            {Object.keys(recipe.data.nutritional_values).map((key) => (
              <tr key={key}>
                <td>
                  <b>{key}:</b>
                </td>
                <td className="text-align-right">{recipe.data.nutritional_values[key]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Typography.Paragraph>
      <Typography.Paragraph className="text-align-right">
        per serving
      </Typography.Paragraph>
    </Card>
  )
}
import {useEffect, useState} from "react";
import {useRecoilValue} from 'recoil'
import {Col, Row, Spin, Typography} from "antd";

import {ChooseOne} from "../../components/choiceui/ChooseOne"
import {RecipeThumb} from "../../components/recipe/RecipeThumb"
import {teaserRecipeAtoms} from "../../atoms/recipes_atom";
import {useRecipesActions} from '../../actions/recipes_actions'

export default function PreferedTeaserRecipeView() {

  const teaserRecipes = useRecoilValue(teaserRecipeAtoms)
  const recipeActions = useRecipesActions()
  const [selectedRecipe, setSelectedRecipe] = useState<number | undefined>(undefined)
  const [busy, setBusy] = useState(true)

  useEffect(() => {
    recipeActions.fetchTeaserRecipes()
      .finally(() => setBusy(false))
  }, [])

  function teaserRecipeOptions() {
    return teaserRecipes.map(
      (recipe, index) => {
        return {
          label: recipe,
          value: index
        }
      }
    )
  }

  return (
    <>
      <Typography.Title level={1}>
        Flavour Profile
      </Typography.Title>

      {busy && (
        <>
          <br />
          <Row className="text-align-center">
            <Col span={24}>
              <Spin size="large" />
            </Col>
            <Col span={24}>
              <br />
              Just a second, getting recipes
            </Col>
          </Row>
        </>
      )}
      {!busy && (
        <>
          {teaserRecipes && (
            <ChooseOne
              legend={
                <Typography.Paragraph>
                  Which dish would you prefer?
                </Typography.Paragraph>
              }
              onChange={setSelectedRecipe}
              options={teaserRecipeOptions()}
              renderOptionLabel={(label: any, checked, i) => {
                return (
                  <RecipeThumb
                    emoji={label.mainIngredient}
                    title={label.title}
                    description={label.description}
                  />
                )
              }}
            />
          )}
        </>
      )}
    </>
  );
}
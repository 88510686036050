import { Card, List, Row, Col, Typography } from "antd";
import { GroupRecipe } from "../../atoms/recipes_atom";

interface RecipeStepsProps {
  recipe: GroupRecipe
}

export default function RecipeStepsCard ({ recipe }: RecipeStepsProps) {

  return (
    <Card type="inner" className="recipe-steps-card">
      <Typography.Title level={4} className="mgt-0">
        Instructions
      </Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={recipe.data.preparation_steps.map(step => {
          let [name, ...rest] = step.description.split(":")
          let desc = rest.join(":")
          if (!rest) {
            desc = name //No name found, so name will be the whole string because that's how split function works
            name = ""
          }
          return {
            emoji: step.emoji,
            name: name,
            description: desc,
          }
        })}
        renderItem={(step, index) => (
          <List.Item>
            <Row className="flex-grow-1" align="middle" gutter={[16, 0]}>
              <Col span={2} className="text-align-center">
                <Typography.Title level={2} className="mg-0" style={{whiteSpace: "nowrap"}}>
                  <span>
                    {step.emoji}
                  </span>
                </Typography.Title>
              </Col>
              <Col span={22}>
                <b>{step.name}:</b>
                {step.description}
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Card>
  )
}
import { LifeLongClient } from "../axios"

export const shoppingListActions = () => {
  const client = LifeLongClient()

  async function index(user_group_id: string) {
    const { data } = await client.client().get(`/user_groups/${user_group_id}/shopping_lists`)
    return data
  }

  async function create(user_group_id: string, items: string[]) {
    const { data } = await client.client().post(`/user_groups/${user_group_id}/shopping_lists`, { items: items })
    return data
  }
  // const setShoppingList = useSetRecoilState(shoppingListAtom)

  // async function fetchShoppingList() {
  //   const { data } = await client.client().get(`/shopping_list`)
  //   setShoppingList(data)
  // }

  // async function addShoppingListItem(item: string) {
  //   await client.client().post(`/shopping_list/items/`, { title: item })
  //   await fetchShoppingList()
  // }

  // async function removeShoppingListItem(id: number) {
  //   await client.client().delete(`/shopping_list/items/${id}`)
  //   await fetchShoppingList()
  // }

  // async function updateShoppingListItem(id: number, state: string) {
  //   await client.client().patch(`/shopping_list/items/${id}`, { state })
  //   await fetchShoppingList()
  // }

  return {
    index,
    create
  }
}

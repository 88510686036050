import {useState, ReactNode, ReactElement} from "react"

import type {Option, Options, Choice, Choices} from './types.js'

export interface MultipleChoiceProps {
  legend: ReactNode //The question
  children?: ReactNode //Any additional content that is needed for the question
  options: Options //
  value?: Choices //For pre-set choices
  onChange?: (choices: Choices) => void
  onAdd?: (choice: Choice) => void
  onRemove?: (choice: Choice) => void
  renderOptionLabel: (label: ReactNode, checked: boolean, i: number) => ReactElement
}

export function MultipleChoice (props: MultipleChoiceProps) {
  const options = props.options
  const blankChoices: Choices = new Set()
  const renderOptionLabel = props.renderOptionLabel
  const [choices, setChoices] = useState(props.value || blankChoices)

  function handleClick (value: Choice) {
    const newChoices = new Set(choices) //add/delete methods on Set modify the existing Set, so make a copy
    if (choices.has(value)) {
      newChoices.delete(value)
      props.onRemove?.(value)
    } else {
      newChoices.add(value)
      props.onAdd?.(value)
    }
    setChoices(newChoices)
    props.onChange?.(newChoices)
  }

  function isChecked (option: Option) {
    return choices.has(option.value)
  }

  //TODO: renderOptions is identical between ChooseOne and MultipleChoice, maybe make some common renderOptions utility component?
  // TODO: This should work in such a way that if you pass in a button, you can render the buttons yourself, but if you pass in anything else, it gets baked inside a button.
  function renderOptions (options: Options) {
    return options.map((option, i) => {
      const checked = isChecked(option)
      const optionElement = renderOptionLabel(option.label, checked, i)

      return (
        <button
          className={"transparent vuo-option " + (optionElement.props.className ? optionElement.props.className : "")}
          aria-pressed={checked ? "true" : "false"}
          key={option.value}
          value={option.value}
          onClick={() => {
            handleClick(option.value)
          }}
        >
          {optionElement}
        </button>
      )
    })
  }
  
  return (
    <fieldset className="vuo-question vuo-multiplechoice">
      <legend>
        {props.legend}
      </legend>
      {props.children}
      <div className="vuo-options">
        {renderOptions(options)}
      </div>
    </fieldset>
  )
}
import {useState} from "react";
import {Typography, Form, Input, Affix, Button} from "antd";
import {useNavigate, useParams, Link} from "react-router-dom";
import {LeftOutlined} from "@ant-design/icons";
import { useRecoilValueLoadable } from "recoil";
import { userGroupRecipeQuery } from "../../atoms/recipes_atom";

import {ReasonNotToCook} from "../../actions/recipes_review_actions";
import {useChefRecipeReviewActions} from "../../actions/chef_recipes_review_actions";
import {ChefRecipeReviewParameters} from "../../actions/recipes_review_actions";
import {MultipleChoice} from "../../components/choiceui/MultipleChoice";
import {ToggleButton} from "../../components/choiceui/ToggleButton.Ant";
import type {Option, Options, Choice, Choices} from '../../components/choiceui/types.js'
import Navbar from "../../components/Navbar"

export default function RecipeReviewCookingFeedbakcView() {
  const navigate = useNavigate()
  const params = useParams()
  const recipeId = String(params.id!)

  const chefRecipeReviewActions = useChefRecipeReviewActions()
  const recipeQuery = useRecoilValueLoadable(userGroupRecipeQuery(recipeId))

  const options = [
    { label: "Too hard to prepare", value: ReasonNotToCook.TooHard },
    { label: "Too much work", value: ReasonNotToCook.TooMuchWork },
    { label: "Too messy", value: ReasonNotToCook.TooMessy },
    { label: "Directions were confusing", value: ReasonNotToCook.DirectionConfusing },
    { label: "Directions were misleading", value: ReasonNotToCook.DirectionMisleading },
  ]

  const [selectedReasons, setSelectedReasons] = useState<ReasonNotToCook[]>([])
  const [customReason, setCustomReason] = useState<string>("")  

  const goToMenu = () => {
    navigate('/')
  }

  const prevClicked = () => {
    // TODO
  }

  const nextClicked = () => {
    const params: ChefRecipeReviewParameters = {
      recipe_id: recipeId,
      reason_not_to_cook: selectedReasons[0],
      open_text_reason: customReason
    }
    chefRecipeReviewActions.updateChefRecipeReview(params).then(() => {
      if (recipeQuery.state === 'hasValue') {
        if (recipeQuery.contents.fill_in_seat) {
          navigate(`/recipe/${recipeId}/review/skill`)
        } else {
          chefRecipeReviewActions.publishChefRecipeReview(recipeId).then(() => {
            navigate('/recipe/review/thanks')
          })
        }
      }
    })    
  }

  return (
    <div className='view-container'>
      <Navbar>
        <Button type="link" shape="round" size="small" onClick={goToMenu}>
          ← Back to Recipes
        </Button>
      </Navbar>
      <MultipleChoice
        legend={
          <Typography.Title level={2} className="view-title">
            Why Not?
          </Typography.Title>
        }
        onChange={(choices: Choices) => setSelectedReasons([...choices])}
        options={options}
        renderOptionLabel={(label, checked, i) => {
          return (
            // TODO: label gets wrapped in a button, this needs to be a visualisation of a toggle, not an actual button.
            <ToggleButton pressed={checked}>
              {label}
            </ToggleButton>
          )
        }}
      />
      <Form layout="vertical">
        <Form.Item
          label={
            <Typography.Title level={4}>
              Something else?
            </Typography.Title>
          }
        >
          <Input onChange={(e) => setCustomReason(e.target.value)} />
        </Form.Item>
      </Form>
      <Affix offsetBottom={0}>
        <div className="actionbar">
          <Button onClick={prevClicked} type="text" size="large" shape="round" htmlType="submit">
            ← Back
          </Button>
          <Button onClick={nextClicked} type="primary" size="large" shape="round" htmlType="submit">
            Next →
          </Button>
        </div>
      </Affix>
    </div>
  )
}
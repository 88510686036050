import { LifeLongClient } from "../axios"

export const userGroupActions = () => {
  const client = LifeLongClient()

  async function show(id: number) {
    const { data } = await client.client().get(`/user_groups/${id}`)
    return data
  }

  async function createUserGroup(name: string) {
    const { data } = await client.client().post(`/user_groups`, { name: name })
    return data
  }

  async function destroy(id: number) {
    const { data } = await client.client().delete(`/user_groups/${id}`)
    return data
  }

  async function index() {
    const { data } = await client.client().get(`/user_groups`)
    return data
  }

  async function removeUser(group_id: number, user_id: number) {
    const { data } = await client.client().delete(`/user_groups/${group_id}/remove_user`, { params: { user_id: user_id } })
    return data
  }

  return {
    show,
    index,
    createUserGroup,
    destroy,
    removeUser
  }
}

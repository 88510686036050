import { DefaultValue, atom, selector, selectorFamily } from 'recoil'
import { UserProfile } from './user_profile_atom'
import { localStorageEffect } from "./local_storage_effect"
import { userGroupActions } from '../actions/user_group_actions'

export type User = {
  id: number
  email: string
  user_profile: UserProfile
}

export type UserGroup = {
  id: number
  members: User[]
  name: string
  owner: User
}

export type UserGroups = {
  owned_user_groups: UserGroup[];
  user_groups: UserGroup[];
}

export const selectedGroupIdState = selector<number | null>({
  key: 'selectedGroupIdKey',
  get: async ({ get }) => {
    const selectedGroupIdValue = get(selectedGroupIdAtom)

    if (selectedGroupIdValue !== null) {
      return selectedGroupIdValue
    }

    const userGroups = get(userGroupsQuery)
    const allGroups = [...userGroups.owned_user_groups, ...userGroups.user_groups]

    if (allGroups.length > 0) {
      return allGroups[0].id
    }
    return null
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(selectedGroupIdAtom, newValue);
    }
  }
})

export const selectedGroupIdAtom = atom<number | null>({
  key: 'selectedGroupIdAtomKey',
  default: null,  // assuming no group is selected by default
  effects: [localStorageEffect("selectedGroupIdAtomKey")],
});

export const userGroupQuery = selectorFamily<UserGroup | null, number | null>({
  key: 'userGroupQuery',
  get: groupID => async () => {
    if (!groupID) { return null }
    return await userGroupActions().show(groupID)
  }
})

export const userGroupsQuery = selector<UserGroups>({
  key: 'userGroupsQueryName',
  get: async ({get}) => {
    get(UserGroupsQueryID)
    return await userGroupActions().index()
  }
})

export const UserGroupsQueryID = atom({
  key: 'UserGroupsQueryID',
  default: 0
})
import { selector } from 'recoil'
import { LifeLongClient } from '../axios'

export type User = {
  id: number
  email: string
}

export const userDataQuery = selector<User>({
  key: 'userDataQuery',
  get: async () => {
    const { data } = await LifeLongClient().client().get(`/my_user/`)
    return data
  }
})
import { useRecoilState } from 'recoil'
import {
  recipesAtom
} from "../atoms/recipes_atom";
import { LifeLongClient } from '../axios'

export enum ReasonNotToCook {
  TooHard = "too_hard",
  TooMuchWork = "too_much_work",
  TooMessy = "too_messy",
  DirectionConfusing = "direction_confusing",
  DirectionMisleading = "direction_misleading",
  Other = "other"
}

export enum ReviewedChefFeedback {
  neutral = 0,
  positive = 1
}

export type ChefRecipeReviewParameters = {
  open_text_reason?: string,
  recipe_id: string,
  reason_not_to_cook?: ReasonNotToCook,
  reviewed_chef_feedback?: ReviewedChefFeedback
  skipped?: boolean,
  would_cook_again?: boolean,
}

function useRecipeReviewActions() {

  const client = LifeLongClient()
  const [recipes, setRecipes] = useRecoilState(recipesAtom)

  async function updateChefRecipeReview(params: ChefRecipeReviewParameters) {
    const { data } = await client.client().patch(`/recipes/${params.recipe_id}/chef_recipe_review`, params)
    const newRecipes = recipes.map(recipe => {
      if (recipe.id === params.recipe_id) {
        const newRecipe = { ... recipe }
        newRecipe.chef_review = data
        return newRecipe
      }
      return recipe
    })
    setRecipes(newRecipes)
  }

  return {
    updateChefRecipeReview
  }
}

export { useRecipeReviewActions }
import { useState, ReactNode } from "react"

import { Form, Space, Button, Col, Input, Row, Typography } from "antd"
import { CloseOutlined } from "@ant-design/icons"

import type {Option, Options, Choice, Choices} from './types.js'

export interface AddMoreProps {
  label: ReactNode //All inputs must have labels.
  button?: ReactNode
  value?: Choices
  error?: ReactNode //For validation messages
  onAdd?: (choice: Choice) => void
  onRemove?: (choice: Choice) => void
  onChange?: (choices: Choices) => void
}

export function AddMore (props: AddMoreProps) {

  const [value, setValue] = useState<string>("")
  const blankChoices: Choices = new Set()
  const [choices, setChoices] = useState(props.value || blankChoices) //Save values that have been chosen
  
  function add (val: string) {
    if (!val) return
    const newChoices = new Set(choices) //add/delete modify the existing set, so make a copy
    newChoices.add(val)
    setChoices(newChoices)
    setValue("")
    props.onAdd?.(val)
    props.onChange?.(newChoices)
  }

  function remove (val: string) {
    const newChoices = new Set(choices) //add/delete modify the existing set, so make a copy
    newChoices.delete(val)
    setChoices(newChoices)
    props.onRemove?.(val)
    props.onChange?.(newChoices)
  }

  return (
    <fieldset className="vuo-question vuo-addmore">
      <div className="vuo-input">
        <legend>
          <Typography.Title level={4}>
            {props.label}
          </Typography.Title>
        </legend>
        <Input
          onChange={(event) => setValue(event.target.value)}
          value={value}
        />
      </div>
      <div className="vuo-error">
        {props.error}
      </div>
      <Button onClick={() => add(value)} className="vuo-button">
        {props.button || "Add"}
      </Button>
      <div className="vuo-choices">
        {choices && Array.from(choices).map((choice, i) => (
          <Button
            key={i}
            type="primary"
            value={choice}
            onClick={() => remove(choice)}
          >
            {choice}
            <CloseOutlined />
          </Button>
        ))}
      </div>
    </fieldset>
  )
}